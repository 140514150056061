
  import { ValidationProvider } from "vee-validate";
  import { Component, Vue, Prop, Emit, Watch, Ref } from "vue-property-decorator";

  @Component({
    components: {
      ValidationProvider,
    },
  })
  export default class FormYesNo extends Vue {
    @Prop() name?: string;

    @Prop() value?: boolean | null;

    @Prop() rules?: string | object;

    @Prop({ default: false }) disabled!: boolean;

    @Prop({ default: false }) yesDisabled!: boolean;

    @Prop({ default: false }) noDisabled!: boolean;

    @Prop() label?: string;

    @Prop({ default: true }) showLabel?: boolean;

    @Prop({ default: false }) nullable?: boolean;

    @Prop({ default: false }) ghostLabel?: boolean;

    @Prop({ default: "flex flex-row" }) toggleClass?: string;

    @Prop({ default: "right" }) labelPosition?: "right" | "top";

    @Prop({ default: "primary" }) theme?: "primary" | "secondary";

    @Prop({ default: true }) trueValue?: any;

    @Prop({ default: false }) falseValue?: any;

    @Ref() readonly provider!: InstanceType<typeof ValidationProvider>;

    localValue: boolean | null = null;

    get formValdiated(): boolean {
      return this.provider.$_veeObserver?.flags?.validated;
    }

    get validateAs(): string {
      if (this.label) {
        return this.label;
      }

      if (this.name) {
        return this.name;
      }

      return `yes/no field`;
    }

    @Emit("input")
    handleInput(v: boolean | null) {

      if (this.nullable && v === this.localValue) {
        v = null;
      }

      this.provider.setFlags({ ...this.provider.flags, dirty: true });
      this.localValue = v;
      return v === true ? this.trueValue : v === false ? this.falseValue : null;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: any) {
      this.localValue = newValue === this.trueValue ? true : newValue === this.falseValue ? false : null;
    }
  }
