import Vue from "vue";
import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";
import { WorkStatus } from "./activity.store";

export interface IndexTaskPayload {
    q?: {
        and: {
            activity_id?: number | number[];
            status?: "DONE" | "TODO" | "WAITING" | "NA";
            deadline?: string;
            not?: {
                phase: string;
            };
        };
    };
    per_page?: number;
    s?: {
        group?: "asc" | "desc";
        ordinal?: "asc" | "desc";
        deadline?: "asc" | "desc";
        created_at?: "asc" | "desc";
        resolved_at?: "asc" | "desc";
        updated_at?: "asc" | "desc";
        id?: "asc" | "desc";
    };
    skip_mutation?: boolean;
}

export enum TaskStatus {
    todo = "TODO",
    requested = "REQUESTED",
    planned = "PLANNED",
    done = "DONE",
    na = "NA",
    waiting = "WAITING",
}

export enum TaskPhase {
    prospect = "PROSPECT",
    newDossier = "NEW_DOSSIER",
    startUp = "START_UP",
    visit = "VISIT",
    published = "PUBLISHED",
    bid = "BID",
    salesAgreement = "SALES_AGREEMENT",
    dossierClosing = "DOSSIER_CLOSING",
    deed = "DEED",
    moving = "MOVING",
}

export interface CreateTaskPayload {
    title: string;
    activity_id: number;
    ordinal?: number;
    status?: TaskStatus;
    deadline?: string | null;
    phase?: WorkStatus;
    group: string | null;
    category: string | null;
    public: boolean;
}

export interface PatchTaskPayload {
    id: number;
    status?: TaskStatus;
    deadline?: string | null;
}

export interface PatchBulkTaskPayload {
    taskIds: number[];
    field: string;
    value: TaskStatus | string | number;
}

export interface TaskAssignee {
    id: number;
    relation_id: number;
    first_name: string;
    last_name: string;
}

export interface Task {
    id: number;
    is_checked?: boolean | null;
    title: string;
    created_at: string;
    deadline: string | null;
    group: string | null;
    category: string | null;
    ordinal: number;
    default_task: boolean;
    user_id: number | null;
    updated_at: string;
    resolved_at: string;
    public: boolean;
    assigned_to: TaskAssignee[];
    seller_assigned_to: TaskAssignee[];
    phase: TaskPhase | null;
    documents: Array<{
        id: number;
        relation_id: number;
    }>;
    status: TaskStatus;
    tasks: Task[];
    sort?: Array<string | number>;
}

interface TaskState {
    all: Task[];
}

const initialState: TaskState = {
    all: [],
};

const getters: GetterTree<TaskState, RootState> = {
    all(state: TaskState): Task[] {
        return state.all;
    },
};

const mutations: MutationTree<TaskState> = {
    RESET(state: TaskState) {
        state.all = [];
    },
    ADD_MODEL(state: TaskState, payload: Task) {
        state.all.push(payload);
    },
    SET_MODELS(state: TaskState, payload: Task[]) {
        state.all = payload;
    },
    ADD_MODELS(state: TaskState, payload: Task[]) {
        state.all = [...state.all, ...payload];
    },
    UPDATE_MODEL(state: TaskState, payload: Task) {
        const index = state.all.findIndex((model: Task) => model.id === payload.id);
        if (index === -1) {
            state.all.push(payload);
        } else {
            Vue.set(state.all, index, payload);
        }
        return;
    },
    DELETE_MODEL(state: TaskState, id: number) {
        // @ts-ignore
        const index = state.all.findIndex((model: Task) => model.id == id);

        if (index >= 0) {
            state.all.splice(index, 1);
        }
    },
};

const actions: ActionTree<TaskState, RootState> = {
    index({ commit, rootState }, payload: IndexTaskPayload) {
        return rootState.api
            .get(`tasks?${payload ? queryFromObject(payload) : ""}`, { withCredentials: true })
            .then((response: { data: Task[]; total: number }) => {
                if (!payload.skip_mutation) {
                    commit("SET_MODELS", response.data);
                }

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    create({ commit, rootState }, payload: CreateTaskPayload) {
        return rootState.api
            .post(`tasks`, payload, { withCredentials: true })
            .then((response: { data: Task }) => {
                commit("UPDATE_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    patch({ commit, rootState }, payload: PatchTaskPayload) {
        return rootState.api
            .patch(`tasks/${payload.id}`, payload, { withCredentials: true })
            .then((response: { data: Task }) => {
                commit("UPDATE_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    patchBulk({ commit, rootState }, payload: PatchBulkTaskPayload) {
        return rootState.api
            .patch(`tasks/bulk`, payload, { withCredentials: true })
            .then((response: { data: Task[] }) => {
                response.data.forEach((task: Task) => {
                    commit("UPDATE_MODEL", task);
                });

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    regenerate({ commit, rootState }, payload: number) {
        return rootState.api
            .post(`/activities/${payload}/tasks-regenerate`, {}, { withCredentials: true })
            .then((response: { data: Task }) => {
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    delete({ commit, rootState }, payload: number) {
        return rootState.api
            .delete(`tasks/${payload}`, { withCredentials: true })
            .then((response: { data: void }) => {
                commit("DELETE_MODEL", payload);
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
