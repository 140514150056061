import { ActionTree, MutationTree, GetterTree } from "vuex";
import { Auth } from "../models/Auth";

const initialState: AuthState = {
    auth: undefined,
};

const getters: GetterTree<AuthState, RootState> = {
    me(state: AuthState): CurrentAccount | undefined {
        return state.auth;
    },
    isAdmin(state: AuthState): boolean | undefined {
        return state.auth && state.auth.permissions && state.auth.permissions.includes("account.delete");
    },
};

const mutations: MutationTree<AuthState> = {
    RESET(state: AuthState) {
        state.auth = undefined;
        // state.steps = steps;
        localStorage.removeItem(`${process.env.VUE_APP_NAME}_SKIP_STEP`);
    },
    SET_AUTH(state: AuthState, payload: any) {
        state.auth = new Auth(payload);
    },
};

const actions: ActionTree<AuthState, RootState> = {
    checkEmail({ rootState }, payload: CheckEmailPayload) {
        return rootState.api
            .post("email/validate", payload, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    login({ rootState }, payload: LoginPayload) {
        return rootState.api
            .post("login", payload, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    passwordlessLogin({ rootState }, payload: LoginPayload) {
        return rootState.api
            .post("passwordless-login", payload, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    requestPasswordlessLogin({ rootState }, payload: RequestPasswordlessLogin) {
        return rootState.api
            .post("request-magic-link", payload, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    register({ rootState }, payload: RegisterPayload) {
        return rootState.api
            .post("register", payload, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    passwordForgot({ rootState }, payload: PasswordForgotPayload) {
        return rootState.api
            .post("password/forgot", payload, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    passwordReset({ rootState }, payload: PasswordResetPayload) {
        return rootState.api
            .post("password/reset", payload, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    passwordCheck({ rootState }, payload: PasswordCheckPayload) {
        return rootState.api
            .post("password/check", payload, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    confirmEmail({ rootState }, payload: ConfirmEmailPayload) {
        return rootState.api
            .post("email/confirm", payload, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    changeEmail({ rootState }, payload: ChangeEmailPayload) {
        return rootState.api
            .post(`accounts/${payload.account_id}/email-changes/requests`, { email: payload.email }, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    cancelChangeEmail({ rootState }, payload: CancelChangeEmailPayload) {
        return rootState.api
            .post(`accounts/${payload.account_id}/email-changes/cancel`, null, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    me({ commit, rootState, state }, dontUseStorage: boolean | undefined) {
        if (state.auth && !dontUseStorage) {
            return new Promise((resolve) => resolve(state.auth));
        }

        return rootState.api
            .get("me", { withCredentials: true })
            .then((response: { data: CurrentAccount }) => {
                commit("SET_AUTH", response.data);

                if (response.data.offices && response.data.offices.length) {
                    if (!rootState.office_id || !response.data.offices.includes(rootState.office_id)) {
                        this.dispatch("setOfficeId", response.data.offices[0]);
                    }
                } else {
                    this.dispatch("clearOfficeId");
                }

                return Promise.resolve(state.auth);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    setAuth({ commit }, payload: CurrentAccount) {
        commit("SET_AUTH", payload);
    },

    logout({ commit, rootState, dispatch }) {
        return rootState.api
            .post("logout", {}, { withCredentials: true })
            .then(() => {
                dispatch("reset", null, { root: true });

                commit("RESET");

                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
