import Vue from "vue";
import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";
import { ConnectivePackageStatus } from "./document.store";

export enum WorkStatus {
  "brokerage_agreement_split_vat" = "BA_SPLIT_VAT",
  "brokerage_agreement_total_vat" = "BA_TOTAL_VAT",
  "brokerage_agreement_outside_salesarea" = "BA_OUTSIDE_SALESAREA",
  "brokerage_agreement_inside_salesarea" = "BA_INSIDE_SALESAREA",
  "brokerage_agreement_non_consumer" = "BA_NON_CONSUMER",
  "pre_contractual_information_sales_mediation" = "PC_INFORMATION_SALES_MEDIATION",
}

export interface IndexMandatePayload {
  q?: {
    and?: {
      activity_id?: number | number[];
    };
  };
  s?: {
    status?: "asc" | "desc";
    updated_at?: "asc" | "desc";
  };
  per_page?: number;
}

export interface CreateMandatePayload {
  activity_id: number;
  details: {};
}

export interface UpdateMandatePayload {
  id: number;
  activity_id: number;
  details: {
    type?: WorkStatus;
    article_2_notary?: string;
  };
}

export interface Mandate {
  id: number;
  activity_id: number;
  status: ConnectivePackageStatus;
  details: {
    type: WorkStatus;
    candidates?: Array<{ not_all_information_known?: boolean; first_name: string; last_name: string; email: string; birth_date: string; media: Media | null }>;
    ground_owner_company_candidates?: Array<{ not_all_information_known?: boolean; first_name: string; last_name: string; email: string; birth_date: string; media: Media | null }>;
    construction_owner_company_candidates?: Array<{
      not_all_information_known?: boolean;
      first_name: string;
      last_name: string;
      email: string;
      birth_date: string;
      media: Media | null;
    }>;
    company_candidates?: Array<{ first_name: string; not_all_information_known?: boolean; last_name: string; email: string; birth_date: string; media: Media | null }>;
    article_2_keys_available?: boolean;
    article_2_keys_available_holder_email?: string;
    article_2_keys_available_holder_first_name?: string;
    article_2_keys_available_holder_last_name?: string;
    article_2_keys_available_holder_phone?: string;
    article_2_keys_available_holder_type?: "owner" | "renter" | "contact";
    article_2_is_property_occupied_occupant_email?: string;
    article_2_is_property_occupied_occupant_first_name?: string;
    article_2_is_property_occupied_occupant_last_name?: string;
    article_2_is_property_occupied_occupant_phone?: string;
    article_2_is_property_occupied_occupant_type?: "owner" | "renter" | "contact";
    article_2_notary?: string;
  };
  created_at: string;
  updated_at: string;
}

interface MandateState {
  all: Mandate[];
}

const initialState: MandateState = {
  all: [],
};

const getters: GetterTree<MandateState, RootState> = {
  all(state: MandateState): Mandate[] {
    return state.all;
  },
  viewing(state: MandateState): Mandate | undefined {
    return state.all[0];
  },
};

const mutations: MutationTree<MandateState> = {
  RESET(state: MandateState) {
    state.all = [];
  },
  ADD_MODEL(state: MandateState, payload: Mandate) {
    state.all.push(payload);
  },
  SET_MODELS(state: MandateState, payload: Mandate[]) {
    state.all = payload;
  },
  ADD_MODELS(state: MandateState, payload: Mandate[]) {
    state.all = [...state.all, ...payload];
  },
  UPDATE_MODEL(state: MandateState, payload: Mandate) {
    if (!state.all) {
      state.all = [payload];
      return;
    }
    const index = state.all.findIndex((model: Mandate) => model.id === payload.id);

    if (index === -1) {
      state.all.push(payload);
    } else {
      Vue.set(state.all, index, payload);
    }
  },
};

const actions: ActionTree<MandateState, RootState> = {
  index({ commit, rootState }, payload: IndexMandatePayload) {
    return rootState.api
      .get(`mandates?${payload ? queryFromObject(payload) : ""}`, { withCredentials: true })
      .then((response: { data: Mandate }) => {
        commit("SET_MODELS", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  read({ commit, rootState }, payload: number) {
    return rootState.api
      .get(`mandates/${payload}`, { withCredentials: true })
      .then((response: { data: Mandate }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  preview({ commit, rootState }, payload: { mandate_id: number; type: WorkStatus }) {
    return rootState.api
      .get(`mandates/${payload.mandate_id}/preview?${payload ? queryFromObject(payload) : ""}`, { withCredentials: true, responseType: "blob" })
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  create({ commit, rootState }, payload: CreateMandatePayload) {
    return rootState.api
      .post(`mandates`, payload, { withCredentials: true })
      .then((response: { data: Mandate }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  update({ commit, rootState }, payload: UpdateMandatePayload) {
    return rootState.api
      .put(`mandates/${payload.id}`, payload, { withCredentials: true })
      .then((response: { data: Mandate }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  delete({ commit, rootState }, payload: number) {
    return rootState.api
      .delete(`mandates/${payload}`, { withCredentials: true })
      .then((response: { data: Mandate }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  patch({ commit, rootState }, payload: UpdateMandatePayload) {
    return rootState.api
      .patch(`mandates/${payload.id}`, payload, { withCredentials: true })
      .then((response: { data: Mandate }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  makeConnectiveDraft({ commit, rootState }, payload: { id: number; document_ids: number[] }) {
    return rootState.api
      .post(`mandates/${payload.id}/connective/draft`, { document_ids: payload.document_ids }, { withCredentials: true })
      .then((response: { data: Document }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  attemptSign({ commit, rootState }, payload: { id: number; actors: ConnectiveSignatureRequestActor[]; unknown_actors: boolean }) {
    return rootState.api
      .post(`mandates/${payload.id}/connective/attempt-sign`, { actors: payload.actors, unknown_actors: payload.unknown_actors }, { withCredentials: true })
      .then((response: { data: Document }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  requestF2FSigningUrl({ commit, rootState }, payload: number) {
    return rootState.api
      .get(`mandates/${payload}/connective/request-f2f-signing-url`, { withCredentials: true })
      .then((response: { data: string }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
