import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";
import Vue from "vue";

export interface IndexActivityFavoritePayload {
    account_id: number;
    q?: {
        and: {
            activity_id?: number | number[];
            account_id?: number | number[];
        };
    };
}

export interface CreateActivityFavoritePayload {
    activity_id: number;
    account_id: number;
}

export interface FavouriteActivity {
    id: number;
    activity_id: number;
    created_at: string;
    updated_at: string;
}

interface ActivityFavoriteState {
    all: FavouriteActivity[];
}

const initialState: ActivityFavoriteState = {
    all: [],
};

const getters: GetterTree<ActivityFavoriteState, RootState> = {
    all(state: ActivityFavoriteState): FavouriteActivity[] {
        return state.all;
    },
};

const mutations: MutationTree<ActivityFavoriteState> = {
    RESET(state: ActivityFavoriteState) {
        state.all = [];
    },
    ADD_MODEL(state: ActivityFavoriteState, payload: FavouriteActivity) {
        state.all.push(payload);
    },
    SET_MODELS(state: ActivityFavoriteState, payload: FavouriteActivity[]) {
        state.all = payload;
    },
    ADD_MODELS(state: ActivityFavoriteState, payload: FavouriteActivity[]) {
        state.all = [...state.all, ...payload];
    },
};

const actions: ActionTree<ActivityFavoriteState, RootState> = {
    index({ commit, rootState }, payload: IndexActivityFavoritePayload) {
        return rootState.api
            .get(`accounts/${payload.account_id}/favourite-activities?${queryFromObject(payload)}`, { withCredentials: true })
            .then((response: { data: FavouriteActivity[]; total: number }) => {
                commit("SET_MODELS", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    create({ commit, rootState }, payload: CreateActivityFavoritePayload) {
        return rootState.api
            .post(`accounts/${payload.account_id}/favourite-activities`, payload, { withCredentials: true })
            .then((response: { data: FavouriteActivity }) => {
                commit("ADD_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    delete({ commit, rootState }, payload: { account_id: number; id: number }) {
        return rootState.api
            .delete(`accounts/${payload.account_id}/favourite-activities/${payload.id}`, { withCredentials: true })
            .then((response: {}) => {
                commit("DELETE_MODEL", payload.id);

                return Promise.resolve(payload.id);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
