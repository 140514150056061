import Vue from "vue";
import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";

export type OfficeSetViewing = (payload: Office) => void;
export type OfficeCreate = (payload: CreateOfficePayload) => Promise<Office>;
export type OfficePatch = (payload: OfficePatchPayload) => Promise<Office>;

export interface IndexOfficePayload {
    q?: {
        and?: {};
    };
    s?: {
        id: "asc" | "desc";
    };
    per_page?: number;
}

export interface CreateOfficePayload {
    name: string;
}

export interface OfficePatchPayload {
    id: number;
    name?: string;
    legal_name?: string;
    legal_address?: {
        zip?: string;
        city?: string;
        number?: string;
        street?: string;
        country?: string;
        additional?: string;
    };
    short_name?: string;
    vat_number?: string;
    escrow?: string;
    representative?: {
        first_name?: string;
        last_name?: string;
        biv_number?: string;
        function?: string;
        function_short?: string;
    };
    partners?: {
        vastgoedexperts_billing_email?: string;
        vastgoedexperts_information_email?: string;
    };
    address?: {
        zip?: string;
        city?: string;
        number?: string;
        street?: string;
        country?: string;
        additional?: string;
    };
    phone?: string;
    email?: string;
    website_url?: string;
    privacy_policy_url?: string;
    type?: string;
    competent_court?: string | null;
    competent_court_department?: string | null;
    theme?: {
        color_primary?: string;
        color_accent?: string;
        color_primary_light?: string;
        color_accent_light?: string;
    };
    signature?: Array<{ name: string; uuid: string }>;
    logo?: Array<{ name: string; uuid: string }>;
    logo_full?: Array<{ name: string; uuid: string }>;
    logo_inverse?: Array<{ name: string; uuid: string }>;
    logo_email?: Array<{ name: string; uuid: string }>;
    logo_small?: Array<{ name: string; uuid: string }>;
    logo_small_inverse?: Array<{ name: string; uuid: string }>;
}

export interface Office {
    id: number;
    name: string;
    legal_name: string | null;
    legal_address:
    | {
          zip: string;
          city: string;
          number: string;
          street: string;
          country: string;
          additional: string;
      }
    | [];
    short_name: string | null;
    vat_number: string | null;
    escrow: string | null;
    type: string | null;
    competent_court: string | null;
    competent_court_department: string | null;
    representative: {
        signature: Media | null;
        first_name: string | null;
        last_name: string | null;
        biv_number: string | null;
        function: string | null;
        function_short: string | null;
    };
    partners: {
        vastgoedexperts_billing_email: string;
        vastgoedexperts_information_email: string;
    };
    address:
        | {
              zip: string;
              city: string;
              number: string;
              street: string;
              country: string;
              additional: string;
          }
        | [];
    phone: string | null;
    email: string | null;
    entity: string | null;
    website_url: string | null;
    privacy_policy_url: string | null;
    theme: {
        logo: {
            logo: Media | null;
            full: Media | null;
            inverse: Media | null;
            email: Media | null;
            small: Media | null;
            small_inverse: Media | null;
        };
        colors: {
            primary: string | null;
            accent: string | null;
            primary_light: string | null;
            accent_light: string | null;
        };
    };
    created_at: string;
    updated_at: string;
}

export interface OfficeState {
    all: Office[];
    viewing?: Office;
}

const initialState: OfficeState = {
    all: [],
    viewing: undefined,
};

const getters: GetterTree<OfficeState, RootState> = {
    all(state: OfficeState): Office[] {
        return state.all;
    },
    viewing(state: OfficeState): Office | undefined {
        return state.viewing;
    },
};

const mutations: MutationTree<OfficeState> = {
    RESET(state: OfficeState) {
        state.all = [];
        state.viewing = undefined;
    },
    ADD_MODEL(state: OfficeState, payload: Office) {
        state.all.push(payload);
    },
    SET_MODELS(state: OfficeState, payload: Office[]) {
        state.all = payload;
    },
    SET_VIEWING(state: OfficeState, payload: Office) {
        state.viewing = payload;
    },
    ADD_MODELS(state: OfficeState, payload: Office[]) {
        state.all = [...state.all, ...payload];
    },
    UPDATE_MODEL(state: OfficeState, payload: Office) {
        if (!state.all) {
            state.all = [payload];
            return;
        }
        const index = state.all.findIndex((model: Office) => model.id === payload.id);

        if (index === -1) {
            state.all.push(payload);
        } else {
            Vue.set(state.all, index, payload);
        }

        if (state.viewing && state.viewing.id === payload.id) {
            state.viewing = payload;
        }
    },
};

const actions: ActionTree<OfficeState, RootState> = {
    index({ commit, rootState, state }, payload?: IndexOfficePayload) {
        if (state.all && state.all.length > 0) {
            return new Promise((resolve) => resolve(state.all));
        }

        return rootState.api
            .get(`offices?${payload ? queryFromObject(payload) : ""}`, { withCredentials: true })
            .then((response: { data: Office[] }) => {
                commit("SET_MODELS", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    create({ commit, rootState }, payload: CreateOfficePayload) {
        return rootState.api
            .post(`offices`, payload, { withCredentials: true })
            .then((response: { data: Office }) => {
                // @ts-ignore
                if (!payload.hasOwnProperty("dont_save") || !payload.dont_save) {
                    commit("ADD_MODEL", response.data);
                }

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    patch({ commit, rootState }, payload: OfficePatchPayload) {
        return rootState.api
            .patch(`offices/${payload.id}`, payload, { withCredentials: true })
            .then((response: { data: Office }) => {
                commit("UPDATE_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    read({ commit, rootState }, payload: number) {
        return rootState.api
            .get(`offices/${payload}`, { withCredentials: true })
            .then((response: { data: Office }) => {
                commit("SET_VIEWING", response.data);

                commit("UPDATE_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    setViewing({ commit }, payload: Office) {
        commit("UPDATE_MODEL", payload);
        commit("SET_VIEWING", payload);
    },
    clearViewing({ commit }) {
        commit("SET_VIEWING", null);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
