
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class FormError extends Vue {
    @Prop() errorResponse?: ErrorResponse;

    customError(error: ServerError | string) {
        if (typeof error === "string") {
            return this.$te(`errors.${error}`) ? this.$t(`errors.${error}`) : error;
        }

        const params = error.params || [];

        //TODO: move this to something abstract.
        if (error.message === "validation.exists" && params.includes("whise_id")) {
            return "Account heeft geen geldig Whise ID.";
        }

        if (error.message === "validation.exists" && params.includes("activities") && params.includes("id") && params.includes("office_id")) {
            return "Je hebt niet de juiste rechten om een aanpassing te doen binnen dit kantoor.";
        }

        return this.$te(`errors.${error.message}`) ? this.$t(`errors.${error.message}`, [  this.$te(`errors.fields.${error.field}`) ? this.$t(`errors.fields.${error.field}`) : error.field , ...(error.params || [])]) : error.message;
    }
}
