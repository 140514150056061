
import { ValidationProvider } from "vee-validate";
import { Component, Vue, Prop, Emit, Watch, Ref } from "vue-property-decorator";

var textMaskCore = require("text-mask-core");
import createNumberMask from "text-mask-addons/dist/createNumberMask";

@Component({
    components: {
        ValidationProvider,
    },
})
export default class FormMoney extends Vue {
    @Prop() name?: string;

    @Prop() value?: string;

    @Prop() placeholder?: string;

    @Prop() rules?: string | object;

    @Prop() label?: string;

    @Prop({ default: true }) showError?: boolean;

    @Prop({ default: false }) disabled!: boolean;

    @Prop({ default: false }) autoFocus!: boolean;

    @Prop({ default: "text-12" }) labelClass!: string;

    @Prop({ default: "" }) inputClass!: string;

    @Prop({ default: false }) fakeRequired!: boolean;

    @Ref() suffix!: HTMLSpanElement;

    @Ref() prefix!: HTMLSpanElement;

    @Ref() input!: HTMLInputElement;

    @Ref() readonly provider!: InstanceType<typeof ValidationProvider>;

    isFocussed = false;

    localValue: Number | null = null;

    prefixWidth = "20px";

    suffixWidth = "20px";

    validAtleastOnce = false;

    hovering = false;

    currencyMask = createNumberMask({
        prefix: "",
        allowDecimal: true,
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: ".",
        decimalSymbol: ",",
        decimalLimit: 4,
        allowNegative: false,
    });

    private mounted() {
        this.setPreSuffixWidth();
        if (this.autoFocus) {
            this.input.focus();
        }
    }

    private get formatted(): string | null {
        if (this.localValue == undefined) {
            return null;
        }

        return textMaskCore.conformToMask(this.localValue.toString(), this.currencyMask).conformedValue;
    }

    private set formatted(value: string | null) {
        if (!value || value === "") {
            this.localValue = null;
            return;
        }

        if (typeof value === "string") {
            //@ts-ignore
            this.localValue = value;

            return;
        }

        this.localValue = textMaskCore.conformToMask(`${value}`.toString().replace(".", ","), this.currencyMask).conformedValue;
    }

    private updated() {
        this.setPreSuffixWidth();
    }

    private setPreSuffixWidth() {
        if (this.hasPrefix) {
            this.prefixWidth = `${this.prefix.offsetWidth + 16}px`;
        }
        if (this.hasSuffix) {
            this.suffixWidth = `${this.suffix.offsetWidth + 16}px`;
        }
    }

    private get formValdiated(): boolean {
        return this.provider.$_veeObserver?.flags?.validated;
    }

    private get validateAs(): string {
        if (this.label) {
            return this.label;
        }

        if (this.placeholder) {
            return this.placeholder;
        }

        if (this.name) {
            return this.name;
        }

        return `money field`;
    }

    private get hasPrefix(): boolean {
        return !!this.$slots.prefix;
    }

    private get hasSuffix(): boolean {
        return !!this.$slots.suffix;
    }

    private eagerIfDirty(_a: { flags: any; errors: any }) {
        var errors = _a.errors;
        var flags = _a.flags;
        if (errors.length) {
            return {
                on: ["input", "change"],
            };
        }

        if (flags.valid && !this.validAtleastOnce) {
            this.validAtleastOnce = true;
        }

        if (flags.dirty) {
            return {
                on: ["change", "blur", "input"],
            };
        }

        return {
            on: ["change"],
        };
    }

    @Emit("input")
    private handleInput() {
        if (!this.localValue) {
            return null;
        }
        let newLocalValue = parseFloat(this.localValue?.toString().replace(/\./g, "").replace(",", "."));

        return newLocalValue;
    }

    @Emit("blur")
    private handleBlur(e: Event): Event {
        if (this.provider && this.provider.flags.dirty) {
            this.validAtleastOnce = true;
        }
        this.isFocussed = false;
        return e;
    }

    @Emit("focus")
    private handleFocus(e: Event): Event {
        this.isFocussed = true;
        return e;
    }

    @Watch("value", { immediate: true })
    private valueChanged(newValue: string | null) {
        this.formatted = newValue;
    }
}
