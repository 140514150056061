import { ActionTree, MutationTree, GetterTree } from "vuex";
import Vue from "vue";
import store from "@/store/index";
import { queryFromObject } from "@/utils/api-functions";
const cloneDeep = require("lodash/cloneDeep");

export interface IndexAccountPayload {
    q?: {
        and?: {
            office_ids?: number | number[];
        };
        or?: {
            id?: number[];
            roles?: Array<"administrator" | "user" | "seller"> | "administrator" | "user" | "seller";
        };
    };
    s?: any;
    per_page?: number;
}

export interface AccountPatchPayload {
    id: number;
    first_name?: string;
    last_name?: string;
    whise_id?: number | null;
    media?: Array<{ name: string; uuid: string }>;
}

const initialState: AccountState = {
    all: [],
    total: undefined,
    viewing: undefined,
};

const getters: GetterTree<AccountState, RootState> = {
    all(state: AccountState): Account[] {
        return state.all;
    },
    total(state: AccountState): number | undefined {
        return state.total;
    },
    viewing(state: AccountState): Account | undefined {
        return state.viewing;
    },
};

const mutations: MutationTree<AccountState> = {
    RESET(state: AccountState) {
        state.all = [];
        state.viewing = undefined;
    },
    UPDATE_MODEL(state: AccountState, payload: Account) {
        const index = state.all.findIndex((model: Account) => model.id === payload.id);

        if (index === -1) {
            state.all.push(payload);
        } else {
            Vue.set(state.all, index, payload);
        }

        if (store.getters["auth/me"].id === payload.id) {
            const mePayload = cloneDeep(payload);
            mePayload.permissions = store.getters["auth/me"].permissions;
            store.dispatch("auth/setAuth", mePayload);
        }

        if (state.viewing && state.viewing.id === payload.id) {
            state.viewing = payload;
        }
    },
    DELETE_MODEL(state: AccountState, id: number) {
        // @ts-ignore
        const index = state.all.findIndex((model: Account) => model.id == id);

        if (index >= 0) {
            state.all.splice(index, 1);
        }

        // @ts-ignore
        if (state.viewing && state.viewing.id === id) {
            state.viewing = undefined;
        }
    },
    ADD_MODEL(state: AccountState, payload: Account) {
        state.all.push(payload);
    },
    SET_MODELS(state: AccountState, payload: Account[]) {
        state.all = payload;
    },
    SET_TOTAL(state: AccountState, payload: number) {
        state.total = payload;
    },
    ADD_MODELS(state: AccountState, payload: Account[]) {
        state.all = [...state.all, ...payload];
    },
    SET_VIEWING(state: AccountState, payload: Account) {
        state.viewing = payload;
    },
};

const actions: ActionTree<AccountState, RootState> = {
    create({ commit, rootState }, payload: AccountPayload) {
        return rootState.api
            .post(`accounts`, payload, { withCredentials: true })
            .then((response: { data: Account }) => {
                commit("ADD_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    update({ commit, rootState }, { payload, id }: { payload: AccountPayload; id: number }) {
        return rootState.api
            .put(`accounts/${id}`, payload, { withCredentials: true })
            .then((response: { data: Account }) => {
                commit("UPDATE_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    patch({ commit, rootState }, payload: AccountPatchPayload) {
        return rootState.api
            .patch(`accounts/${payload.id}`, payload, { withCredentials: true })
            .then((response: { data: Account }) => {
                commit("UPDATE_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    index({ commit, rootState }, payload: IndexAccountPayload) {
        return rootState.api
            .get(`accounts?${queryFromObject(payload)}`, { withCredentials: true })
            .then((response: { data: Account[]; total: number }) => {
                commit("SET_MODELS", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    indexForCurrentOffice({ commit, rootState, state }, payload: IndexAccountPayload) {
        let p = { q: { and: { office_ids: rootState.office_id, ...payload.q } }, per_page: payload.per_page };
        return this.dispatch("account/index", p);
    },

    read({ commit, rootState }, payload: { id: number }) {
        return rootState.api
            .get(`accounts/${payload.id}`, { withCredentials: true })
            .then((response: { data: Account }) => {
                commit("UPDATE_MODEL", response.data);

                commit("SET_VIEWING", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    invite({ rootState }, payload: AccountInvitePayload) {
        return rootState.api
            .post(`invites`, payload, { withCredentials: true })
            .then((response: { data: Account }) => {
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    delete({ commit, rootState }, payload: { id: number }) {
        return rootState.api
            .delete(`accounts/${payload.id}`, { withCredentials: true })
            .then(() => {
                commit("DELETE_MODEL", payload.id);

                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    setViewing({ commit }, payload: Account) {
        commit("UPDATE_MODEL", payload);
        commit("SET_VIEWING", payload);
    },
    clearViewing({ commit }) {
        commit("SET_VIEWING", null);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
