
import { ValidationProvider } from "vee-validate";
import { Component, Vue, Prop, Emit, Watch, Ref } from "vue-property-decorator";

@Component({
    components: {
        ValidationProvider,
    },
})
export default class FormBadgeSelect extends Vue {
    @Prop() name?: string;

    @Prop({ default: "forms.options" }) i18n?: string;

    @Prop({ default: () => {} }) value?: { [key: string]: boolean };

    @Prop() placeholder?: string;

    @Prop() rules?: string | object;

    @Prop() label?: string;

    @Prop() options?: [];

    @Prop({ default: true }) showError?: boolean;

    @Prop({ default: false }) disabled!: boolean;

    @Ref() readonly provider!: InstanceType<typeof ValidationProvider>;

    localValue: { [key: string]: boolean } = {};

    render = 0;

    private get validateAs(): string {
        if (this.label) {
            return this.label;
        }

        if (this.placeholder) {
            return this.placeholder;
        }

        if (this.name) {
            return this.name;
        }

        return `bade select field`;
    }

    private addOption(option: string) {
        if (!this.localValue) {
            this.localValue = {};
        }

        if (this.localValue[option]) {
            this.localValue[option] = false;
        } else {
            this.localValue[option] = true;
        }

        this.render++;
        this.handleInput();
    }

    @Emit("input")
    private handleInput(): { [key: string]: boolean } {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    private valueChanged(newValue: { [key: string]: boolean }) {
        if (typeof newValue !== "undefined") {
            this.localValue = newValue;
        }
    }
}
