import { ActionTree, MutationTree, GetterTree } from "vuex";

const initialState: InviteState = {
    viewing: undefined,
};

const getters: GetterTree<InviteState, RootState> = {
    viewing(state: InviteState): Invite | undefined {
        return state.viewing;
    },
};

const mutations: MutationTree<InviteState> = {
    RESET(state: InviteState) {
        state.viewing = undefined;
    },
    SET_VIEWING(state: InviteState, payload: Invite) {
        state.viewing = payload;
    },
};

const actions: ActionTree<InviteState, RootState> = {
    create({ commit, rootState }, payload: InvitePayload) {
        return rootState.api
            .post(`invites`, payload, { withCredentials: true })
            .then((response: { data: Invite }) => {
                commit("ADD_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    read({ commit, rootState }, payload: { invite_code: string }) {
        return rootState.api
            .get(`invites/${payload.invite_code}`, { withCredentials: true })
            .then((response: { data: Invite }) => {
                commit("SET_VIEWING", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    resendActivityInvite({ commit, rootState }, payload: { activity_id: number; contact_id: number; activity_seller_id: number }) {
        return rootState.api
            .post(`activities/${payload.activity_id}/resend-invite`, payload, { withCredentials: true })
            .then((response: { data: Invite }) => {
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    delete({ rootState }, payload: { id: number }) {
        return rootState.api
            .delete(`invites/${payload.id}`, { withCredentials: true })
            .then(() => {
                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
