import { ActionTree, MutationTree, GetterTree } from "vuex";
import Vue from "vue";

export interface PackageSignPreview {
    url: string;
}

interface PackageState {}

const initialState: PackageState = {};

const getters: GetterTree<PackageState, RootState> = {};

const mutations: MutationTree<PackageState> = {};

const actions: ActionTree<PackageState, RootState> = {
    requestPreview({ rootState }, packageId: number) {
        return rootState.api
            .get(`packages/${packageId}/sign-preview`, { withCredentials: true })
            .then((response: { data: PackageSignPreview }) => {
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
