
  import { Office } from "@/store/modules/office.store";
  import { ValidationProvider } from "vee-validate";
  import { Component, Vue, Prop, Emit, Watch, Ref } from "vue-property-decorator";

  @Component({
    components: {
      ValidationProvider,
    },
  })
  export default class FormYesOfficeOg extends Vue {
    @Prop() name?: string;

    @Prop() value?: "available" | "provided_by_topo" | "provided_by_seller" | "nvt" | null;

    @Prop() rules?: string | object;

    @Prop() office?: Office;

    @Prop({ default: false }) disabled!: boolean;

    @Prop({ default: false }) nvt!: boolean;

    @Prop() label?: string;

    @Prop({ default: false }) ghostLabel?: boolean;

    @Ref() readonly provider!: InstanceType<typeof ValidationProvider>;

    localValue: "available" | "provided_by_topo" | "provided_by_seller" | "nvt" | null = null;

    get formValdiated(): boolean {
      return this.provider.$_veeObserver?.flags?.validated;
    }

    get validateAs(): string {
      if (this.label) {
        return this.label;
      }

      if (this.name) {
        return this.name;
      }

      return `yes/no field`;
    }

    @Emit("input")
    handleInput(v: "available" | "provided_by_topo" | "provided_by_seller" | "nvt" | null) {
      if (this.disabled) {
        return;
      }

      this.provider.setFlags({ ...this.provider.flags, dirty: true });
      if (this.localValue === v) {
        this.localValue = null;
        return this.localValue;
      }
      this.localValue = v;
      return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: "available" | "provided_by_topo" | "provided_by_seller" | "nvt" | null) {
      this.localValue = newValue;
    }
  }
