var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ValidationProvider',{ref:"provider",staticClass:"flex flex-wrap gap-[16px]",class:{ 'items-center': _vm.label },attrs:{"mode":"eager","vid":_vm.name,"name":_vm.validateAs,"rules":_vm.rules,"bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({ classes, errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],ref:"input",staticClass:"hidden",attrs:{"type":"checkbox","name":_vm.name,"id":`id_${_vm.name}`,"disabled":_vm.disabled},domProps:{"value":true,"checked":Array.isArray(_vm.localValue)?_vm._i(_vm.localValue,true)>-1:(_vm.localValue)},on:{"change":function($event){var $$a=_vm.localValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=true,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localValue=$$c}}}}),_c('div',{staticClass:"flex flex-row toggle-row",class:{ 'cursor-not-allowed opacity-80': _vm.disabled }},[_c('button',{staticClass:"checkbox rounded-l-[8px]",class:{
        '!bg-primary-300': _vm.localValue === 'available',
        'w-[41px]': !_vm.$slots.yes,
        'px-[16px]': _vm.$slots.yes,
        '!border-t-red !border-l-red  !border-b-red invalid': errors[0] && (classes.dirty || _vm.formValdiated),
        'opacity-80 cursor-not-allowed': _vm.disabled,
      },on:{"click":function($event){$event.preventDefault();!_vm.disabled ? _vm.handleInput('available') : null}}},[_c('icon-check',{staticClass:"fill-current h-[20px]"})],1),_c('button',{staticClass:"checkbox",class:{
        '!bg-primary-300': _vm.localValue === 'provided_by_topo',
        'w-[41px]': !_vm.$slots.no,
        'px-[16px]': _vm.$slots.no,
        '!border-t-red   !border-b-red': errors[0] && (classes.dirty || _vm.formValdiated),
        'opacity-80 cursor-not-allowed': _vm.disabled,
      },on:{"click":function($event){$event.preventDefault();!_vm.disabled ? _vm.handleInput('provided_by_topo') : null}}},[(_vm.office && _vm.office.theme.logo.logo)?_c('img',{staticClass:"h-[20px]",attrs:{"src":_vm.office.theme.logo.logo.original_url}}):(_vm.office && _vm.office.theme.logo.small)?_c('img',{staticClass:"h-[20px]",attrs:{"src":_vm.office.theme.logo.small.original_url}}):_c('icon-office-building',{staticClass:"fill-current h-[20px]"})],1),_c('button',{staticClass:"checkbox",class:{
        'rounded-r-[8px]': !_vm.ghostLabel && !_vm.nvt,
        '!bg-primary-300': _vm.localValue === 'provided_by_seller',
        'w-[41px]': !_vm.$slots.no,
        'px-[16px]': _vm.$slots.no,
        '!border-t-red  !border-r-red !border-b-red': errors[0] && (classes.dirty || _vm.formValdiated),
        'opacity-80 cursor-not-allowed': _vm.disabled,
      },on:{"click":function($event){$event.preventDefault();!_vm.disabled ? _vm.handleInput('provided_by_seller') : null}}},[_c('icon-user',{staticClass:"fill-current h-[20px]"})],1),(_vm.nvt)?_c('button',{staticClass:"checkbox rounded-r-[8px]",class:{
        '!bg-primary-300': _vm.localValue === 'nvt',
        'w-[41px]': !_vm.$slots.no,
        'px-[16px]': _vm.$slots.no,
        '!border-t-red  !border-r-red !border-b-red': errors[0] && (classes.dirty || _vm.formValdiated),
        'opacity-80 cursor-not-allowed': _vm.disabled,
      },on:{"click":function($event){$event.preventDefault();!_vm.disabled ? _vm.handleInput('nvt') : null}}},[_c('span',{staticClass:"font-semi"},[_vm._v("nvt")])]):(_vm.ghostLabel)?_c('button',{staticClass:"checkbox rounded-r-[8px] w-[41px] pointer-events-none !bg-primary-300 !text-primary-300 !bg-opacity-30",attrs:{"disabled":true}},[_c('span',{staticClass:"font-semi"},[_vm._v("nvt")])]):_vm._e()]),(_vm.label)?_c('label',{staticClass:"text-black-300 text-[16px] leading-[21px] flex-1",domProps:{"innerHTML":_vm._s(_vm.label)}}):_vm._e(),(errors[0] && (classes.dirty || _vm.formValdiated))?_c('span',{staticClass:"text-red text-12 block w-full flex-none",domProps:{"innerHTML":_vm._s(errors[0])}}):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }