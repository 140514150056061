import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";
import Vue from "vue";

export enum ContactType {
    owner = "OWNER",
    contact = "CONTACT",
    notary = "NOTARY",
    syndic = "SYNDIC",
    buyer = "BUYER",
    potentialBuyer = "POTENTIAL_BUYER",
    potentialRenter = "POTENTIAL_RENTER",
    renter = "RENTER",
}

export interface IndexActivitySellerPayload {
    q: {
        and: {
            activity_id: number | number[];
        };
    };
    per_page: number;
}

export interface CreateActivitySellerPayload {
    activity_id: number;
    seller_id: number;
    notify: boolean;
    dashboard_access?: boolean | null;
    type: ContactType;
}
export interface UpdateActivitySellerPayload {
    id: number;
    activity_id: number;
    seller_id: number;
    notify: boolean;
    dashboard_access?: boolean | null;
    type: ContactType;
}

export interface ActivitySeller {
    id: number;
    activity_id: number;
    seller_id: number;
    notify: boolean;
    dashboard_access: boolean | null;
    type: ContactType;
}

interface ActivitySellerState {
    all: ActivitySeller[];
}

const initialState: ActivitySellerState = {
    all: [],
};

const getters: GetterTree<ActivitySellerState, RootState> = {
    all(state: ActivitySellerState): ActivitySeller[] {
        return state.all;
    },
};

const mutations: MutationTree<ActivitySellerState> = {
    RESET(state: ActivitySellerState) {
        state.all = [];
    },
    ADD_MODEL(state: ActivitySellerState, payload: ActivitySeller) {
        state.all.push(payload);
    },
    SET_MODELS(state: ActivitySellerState, payload: ActivitySeller[]) {
        state.all = payload;
    },
    ADD_MODELS(state: ActivitySellerState, payload: ActivitySeller[]) {
        state.all = [...state.all, ...payload];
    },
    UPDATE_MODEL(state: ActivitySellerState, payload: ActivitySeller) {
        const index = state.all.findIndex((model: ActivitySeller) => model.id === payload.id);

        if (index === -1) {
            state.all.push(payload);
        } else {
            Vue.set(state.all, index, payload);
        }
    },
    DELETE_MODEL(state: ActivitySellerState, id: number) {
        const index = state.all.findIndex((model: ActivitySeller) => model.id == id);

        if (index >= 0) {
            state.all.splice(index, 1);
        }
    },
};

const actions: ActionTree<ActivitySellerState, RootState> = {
    index({ commit, rootState }, payload: IndexActivitySellerPayload) {
        return rootState.api
            .get(`activity-sellers?${queryFromObject(payload)}`, { withCredentials: true })
            .then((response: { data: ActivitySeller[]; total: number }) => {
                commit("SET_MODELS", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    create({ commit, rootState }, payload: CreateActivitySellerPayload) {
        return rootState.api
            .post(`activity-sellers`, payload, { withCredentials: true })
            .then((response: { data: ActivitySeller }) => {
                commit("ADD_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    update({ commit, rootState }, payload: UpdateActivitySellerPayload) {
        return rootState.api
            .put(`activity-sellers/${payload.id}`, payload, { withCredentials: true })
            .then((response: { data: ActivitySeller }) => {
                commit("UPDATE_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    delete({ commit, rootState }, id: number) {
        return rootState.api
            .delete(`activity-sellers/${id}`, { withCredentials: true })
            .then((response: { data: ActivitySeller }) => {
                commit("DELETE_MODEL", id);

                return Promise.resolve();
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
