
  import { ValidationProvider } from "vee-validate";
  import { Component, Vue, Prop, Emit, Watch, Ref } from "vue-property-decorator";

  @Component({
    components: {
      ValidationProvider,
    },
  })
  export default class FormNumber extends Vue {
    @Prop() name?: string;

    @Prop() value?: number;

    @Prop() placeholder?: string;

    @Prop() rules?: string | object;

    @Prop() label?: string;

    @Prop({ default: true }) showError?: boolean;

    @Prop({ default: false }) disabled!: boolean;

    @Ref() readonly provider!: InstanceType<typeof ValidationProvider>;

    localValue = 0;

    handleMin(): void {
      if (this.localValue === 0) {
        return;
      }
      this.localValue -= 1;
      this.handleInput();
    }

    handlePlus(): void {
      this.localValue++;
      this.handleInput();
    }

    private get validateAs(): string {
      if (this.label) {
        return this.label;
      }

      if (this.placeholder) {
        return this.placeholder;
      }

      if (this.name) {
        return this.name;
      }

      return `number field`;
    }

    @Emit("input")
    private handleInput(): number {
      return this.localValue;
    }

    @Watch("value", { immediate: true })
    private valueChanged(newValue: number) {
      this.localValue = newValue ?? 0;
    }
  }
