import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";
import Vue from "vue";

export interface IndexSellerPayload {
    q?: {
        or?: {
            id?: number | number[];
            email?: string;
            phone?: string;
            activity_ids?: number | number[];
            office_ids?: number | number[];
        };
    };
    skip_mutation?: boolean;
    per_page?: number;
}

export interface CreateSellerPayload {
    email: string | null;
    phone: string | null;
    first_name: string;
    last_name: string;
}

export interface Seller {
    id: number;
    account_id: number | null;
    email: string | null;
    phone: string | null;
    first_name: string;
    last_name: string;
    invited_at: string | null;
    created_at: string;
    updated_at: string;
}

interface SellerState {
    all: Seller[];
}

const initialState: SellerState = {
    all: [],
};

const getters: GetterTree<SellerState, RootState> = {
    all(state: SellerState): Seller[] {
        return state.all;
    },
};

const mutations: MutationTree<SellerState> = {
    RESET(state: SellerState) {
        state.all = [];
    },
    ADD_MODEL(state: SellerState, payload: Seller) {
        state.all.push(payload);
    },
    SET_MODELS(state: SellerState, payload: Seller[]) {
        state.all = payload;
    },
    ADD_MODELS(state: SellerState, payload: Seller[]) {
        state.all = [...state.all, ...payload];
    },
    UPDATE_MODEL(state: SellerState, payload: Seller) {
        const index = state.all.findIndex((model: Seller) => model.id === payload.id);

        if (index === -1) {
            state.all.push(payload);
        } else {
            Vue.set(state.all, index, payload);
        }
    },
};

const actions: ActionTree<SellerState, RootState> = {
    index({ commit, rootState }, payload: IndexSellerPayload) {
        return rootState.api
            .get(`sellers?${queryFromObject(payload)}`, { withCredentials: true })
            .then((response: { data: Seller[]; total: number }) => {
                if (!payload.skip_mutation) {
                    commit("SET_MODELS", response.data);
                }

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    read({ commit, rootState }, payload: number) {
        return rootState.api
            .get(`sellers/${payload}`, { withCredentials: true })
            .then((response: { data: Seller }) => {
                commit("ADD_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    create({ commit, rootState }, payload: CreateSellerPayload) {
        return rootState.api
            .post(`sellers`, payload, { withCredentials: true })
            .then((response: { data: Seller }) => {
                commit("ADD_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    update({ commit, rootState }, { payload, id }: { payload: CreateSellerPayload; id: number }) {
        return rootState.api
            .put(`sellers/${id}`, payload, { withCredentials: true })
            .then((response: { data: Seller }) => {
                commit("UPDATE_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    clear({ commit }) {
        commit("RESET");
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
