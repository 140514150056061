import Vue from "vue";
import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";

export enum PartnerType {
  photo = "PHOTO",
  epcInspector = "EPC_INSPECTOR",
  electricityInspector = "ELECTRICITY_INSPECTOR",
  fuelOilTankInspector = "FUEL_OIL_TANK_INSPECTOR",
  asbestosInspector = "ASBESTOS_INSPECTOR",
  comboEpcInspector = "COMBI_EPC_INSPECTOR",
  surveyor = "SURVEYOR",
  vgEEpc = "VGE_EPC",
  vgEEk = "VGE_EK",
  vgEFuelOilTank = "VGE_FUEL_OIL_TANK",
  vgEAsbestos = "VGE_ASBESTOS",
  vgEImmoSigns = "VGE_IMMO_SIGNS",
}

export enum EpcType {
  EPCAppartment = "epc_appartement",
  EPCClosedBuilding = "epc_geslotenbebouwing",
  EPCsemiDetachedBuilding = "epc_halfopenbebouwing",
  EPCOpenBuilding = "epc_openbebouwing",
  EPCOpenBuilding350 = "epc_openbebouwing_350",
  EPCNonResidential250 = "epc_niet_residentieel_250",
  EPCNonResidential251 = "epc_niet_residentieel_251",
  EPCCommonParts = "epc_gemeenschappelijke_delen",
  EPCNonResidential500 = "epc_niet_residentieel_500",
}

export enum EkType {
  ElectricalInspection = "ek_keuring",
  ElectricalRecheck = "ek_herkeuring",
  ElectricalInspectionNonResidential = "ek_niet_residentieel",
}

export enum AsbestosType {
  Asbestos = "asbestinventaris",
}
export enum ImmoSignType {
  ImmoSignDefault = "immoborden_standaard",
}

export enum FueltankType {
  FuelOilTankInspectionAboveSurface = "stookolietank_keuring_bovengronds",
  FuelOilTankInspectionBelowSurface = "stookolietank_keuring_ondergronds",
}

export interface PartnerServiceAddon {
  title: string;
}
export interface PartnerService {
  title: string;
  addons?: PartnerServiceAddon[];
}

export interface PartnerOffice {
  id: number;
  name: string;
}

export interface PartnerAccount {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  created_at: string;
}

export interface PartnerInvite {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  created_at: string;
}

export interface CreatePartnerPayload {
  company_name: string;
  type: PartnerType;
  office_ids: number[];
  services?: PartnerService[];
  accounts?: Array<{
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
  }>;
}

export interface PatchPartnerPayload {
  id: number;
  company_name?: string;
  office_ids?: number[];
  type?: PartnerType;
  services?: PartnerService[];
}

export interface Partner {
  id: number;
  company_name: string;
  type: PartnerType;
  services?: PartnerService[];
  offices?: PartnerOffice[];
  accounts?: PartnerAccount[];
  invites?: PartnerInvite[];
  sort?: Array<string | number>;
}

interface PartnerState {
  all: Partner[];
}

const initialState: PartnerState = {
  all: [],
};

const getters: GetterTree<PartnerState, RootState> = {
  all(state: PartnerState): Partner[] {
    return state.all;
  },
};

const mutations: MutationTree<PartnerState> = {
  RESET(state: PartnerState) {
    state.all = [];
  },
  ADD_MODEL(state: PartnerState, payload: Partner) {
    state.all.push(payload);
  },
  SET_MODELS(state: PartnerState, payload: Partner[]) {
    state.all = payload;
  },
  ADD_MODELS(state: PartnerState, payload: Partner[]) {
    state.all = [...state.all, ...payload];
  },
  UPDATE_MODEL(state: PartnerState, payload: Partner) {
    const index = state.all.findIndex((model: Partner) => model.id === payload.id);
    if (index === -1) {
      state.all.push(payload);
    } else {
      Vue.set(state.all, index, payload);
    }
    return;
  },
  DELETE_MODEL(state: PartnerState, id: number) {
    // @ts-ignore
    const index = state.all.findIndex((model: Partner) => model.id == id);

    if (index >= 0) {
      state.all.splice(index, 1);
    }
  },
};

const actions: ActionTree<PartnerState, RootState> = {
  index({ commit, rootState }, payload) {
    return rootState.api
      .get(`partners?${payload ? queryFromObject(payload) : ""}`, { withCredentials: true })
      .then((response: { data: Partner[]; total: number }) => {
        if (!payload.skip_mutation) {
          commit("SET_MODELS", response.data);
        }

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  read({ commit, rootState }, payload: number) {
    return rootState.api
      .get(`partners/${payload}`, { withCredentials: true })
      .then((response: { data: Partner[]; total: number }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  create({ commit, rootState }, payload: CreatePartnerPayload) {
    return rootState.api
      .post(`partners`, payload, { withCredentials: true })
      .then((response: { data: Partner }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  patch({ commit, rootState }, payload: PatchPartnerPayload) {
    return rootState.api
      .patch(`partners/${payload.id}`, payload, { withCredentials: true })
      .then((response: { data: Partner }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  delete({ commit, rootState }, payload: number) {
    return rootState.api
      .delete(`partners/${payload}`, { withCredentials: true })
      .then((response: { data: void }) => {
        commit("DELETE_MODEL", payload);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
