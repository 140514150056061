import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store/index";
import { loadView, handleUnauthorized } from "@/router/helpers";

/*
 * MIDDLEWARE
 */
import guardAuth from "@/router/middleware/auth";
import guardLogout from "@/router/middleware/logout";
import guardRedirectIfLoggedIn from "@/router/middleware/redirectIfLoggedIn";

/*
 * ROUTES
 */
import guestRoutes from "@/router/routes/guest";
import sellerRoutes from "@/router/routes/seller";
import mandateRoutes from "@/router/routes/mandate";
import dossierRoutes from "@/router/routes/dossier";
import partnerRoutes from "@/router/routes/partner";
import accountRoutes from "@/router/routes/account";
import salespipelineRoutes from "@/router/routes/sales-pipeline";
import myworkRoutes from "@/router/routes/my-work";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    beforeEnter: guardRedirectIfLoggedIn,
    component: loadView("home"),
  },
  ...guestRoutes,
  ...sellerRoutes,
  ...partnerRoutes,
  {
    path: "/",
    name: "auth",
    component: loadView("auth/index"),
    children: [...dossierRoutes, ...salespipelineRoutes, ...myworkRoutes, ...accountRoutes],
  },
  ...mandateRoutes,
  {
    path: "/logout",
    name: "logout",
    beforeEnter: guardLogout,
    component: loadView("home"),
  },
  {
    path: "/errors/:code",
    name: "error",
    component: loadView("errors/general"),
    meta: {
      title: "Errors | Hubr",
      group: ["auth"],
      beforeResolve(to: any, from: any, next: any) {
        store.dispatch("startLoadingPage");
        next();
        store.dispatch("stopLoadingPage");
      },
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeResolve(async (routeTo: any, routeFrom: any, next: any) => {
  try {
    if (routeFrom.meta && routeFrom.meta.leaveCallback) {
      await routeFrom.meta.leaveCallback(routeTo, routeFrom);
    }

    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta && route.meta.title) {
          document.title = route.meta.title;
        }
        if (route.meta && route.meta.group) {
          document.querySelector("body")?.classList.add(route.meta.group);
        }

        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args: any) => {
            if (args.length) {
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve({ success: true });
            }
          });
        } else {
          resolve({ success: true });
        }
      });
    }
  } catch (error) {
    return;
  }
  next();
});

export default router;
