import guardAuth from "@/router/middleware/auth";
import { loadView } from "@/router/helpers";

const routes = [
  {
    path: "/dossiers",
    name: "files",
    component: loadView("auth/dossier/index"),
    beforeEnter: guardAuth,
    meta: {
      title: "Verkoop pipeline | Hubr",
      section: "auth",
      group: ["employee"],
    },
  },
  {
    path: "/dossier/:id",
    name: "dossier-detail",
    component: loadView("auth/dossier/read"),
    beforeEnter: guardAuth,
    meta: {
      title: "Dossier | Hubr",
      section: "auth",
      group: ["employee"],
    },
  },
  {
    path: "/dossier/:id/settings",
    name: "dossier-settings",
    component: loadView("auth/dossier/settings"),
    beforeEnter: guardAuth,
    meta: {
      title: "Dossier settings | Hubr",
      section: "auth",
      group: ["employee"],
    },
  },
  {
    path: "/dossier/:id/pandfiche",
    name: "dossier-property-create",
    component: loadView("auth/properties/create-tablet"),
    beforeEnter: guardAuth,
    meta: {
      title: "Pandfiche | Hubr",
      section: "auth",
      group: ["employee"],
    },
  },
  {
    path: "/dossier/:id/pandfiche/desktop",
    name: "dossier-property-create-desktop",
    component: loadView("auth/properties/create-desktop"),
    beforeEnter: guardAuth,
    meta: {
      title: "Pandfiche | Hubr",
      section: "auth",
      group: ["employee"],
    },
  },
  {
    path: "/dossier/:id/pandfiche/viewer",
    name: "dossier-property-viewer",
    component: loadView("auth/properties/viewer"),
    beforeEnter: guardAuth,
    meta: {
      title: "Pandfiche | Hubr",
      section: "auth",
      group: ["employee"],
    },
  },

  {
    path: "/dossier/:id/bid",
    name: "dossier-bid-create",
    component: loadView("auth/bids/create"),
    beforeEnter: guardAuth,
    meta: {
      title: "Bod aanmaken | Hubr",
      section: "auth",
      group: ["employee"],
    },
  },

  {
    path: "/dossier/:id/bid/:bidId",
    name: "dossier-bid-update",
    component: loadView("auth/bids/create"),
    beforeEnter: guardAuth,
    meta: {
      title: "Bod aanpassen | Hubr",
      section: "auth",
      group: ["employee"],
    },
  },

  {
    path: "/dossier/:id/bid/:bidId/approve",
    name: "dossier-bid-update-approve",
    component: loadView("auth/bids/create"),
    beforeEnter: guardAuth,
    meta: {
      title: "Bod aanpassen | Hubr",
      section: "auth",
      group: ["employee"],
    },
  },
];

export default routes;
