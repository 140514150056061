import Vue from "vue";
import { queryFromObject } from "@/utils/api-functions";
import { ActionTree, MutationTree, GetterTree } from "vuex";

const initialState: MyWorkState = {
  activityActionLabels: [],
};

const getters: GetterTree<MyWorkState, RootState> = {
  activityActionLabels(state: MyWorkState): MyWorkActivityActionLabel[] {
    return state.activityActionLabels;
  },
};

const mutations: MutationTree<MyWorkState> = {
  SET_ACTIVITY_LABELS(state: MyWorkState, payload: MyWorkActivityActionLabel[]) {
    state.activityActionLabels = payload;
  },
  MERGE_ACTIVITY_LABELS(state: MyWorkState, payload: MyWorkActivityActionLabel[]) {
    const labels = state.activityActionLabels;
    const existingLabelIds = new Set(labels.map((label) => label.id));
    payload.forEach((label) => {
      if (!existingLabelIds.has(label.id)) {
        labels.push(label);
        existingLabelIds.add(label.id);
      }
    });
  },
};

const actions: ActionTree<MyWorkState, RootState> = {
  /**
   * @description Index My work activities, best used with an assigned user.
   *
   * @param {MyWorkActivityIndexPayload} payload - The index payload can be used for the following resources.
   * @returns {MyWorkActivityList}
   */
  async indexActivities({ commit, rootState }, payload: MyWorkActivityIndexPayload) {
    try {
      const response = await rootState.api.get(`my-work/activities?${queryFromObject(payload)}`, { withCredentials: true });

      return response.data as MyWorkActivityList;
    } catch (e: any) {
      return e as ErrorResponse;
    }
  },

  /**
   * @description Search My work activities, best used with an assigned user.
   *
   * @param {MyWorkActivityIndexPayload} payload - The index payload can be used for the following resources.
   * @returns {MyWorkActivityList}
   */
  async searchActivities({ commit, rootState }, payload: MyWorkActivityIndexPayload) {
    try {
      const response = await rootState.api.get(`my-work/activities/search?${queryFromObject(payload)}`, { withCredentials: true });

      return response.data as MyWorkActivityList;
    } catch (e: any) {
      return e as ErrorResponse;
    }
  },

  /**
   * @description Index uscheduled My work activities, best used with an assigned user.
   *
   * @param {MyWorkActivityIndexPayload} payload - The index payload can be used for the following resources.
   * @returns {MyWorkActivity[]}
   */
  async indexUnscheduledActivities({ commit, rootState }, payload: MyWorkActivityIndexPayload) {
    try {
      const response = await rootState.api.get(`my-work/activities/unscheduled?${queryFromObject(payload)}`, { withCredentials: true });

      return response.data as MyWorkActivity[];
    } catch (e: any) {
      return e as ErrorResponse;
    }
  },

  /**
   * @description Index My work tasks, best used with an assigned user and a search string.
   *
   * @param {MyWorkTaskIndexPayload} payload - The index payload can be used for the following resources.
   * @returns {MyWorkTask[]}
   */
  async indexTasks({ commit, rootState }, payload: MyWorkTaskIndexPayload) {
    try {
      const response = await rootState.api.get(`my-work/tasks?${queryFromObject(payload)}`, { withCredentials: true });

      return response.data as MyWorkTask[];
    } catch (e: any) {
      return e as ErrorResponse;
    }
  },

  /**
   * @description Search My work tasks to use in the search filter.
   *
   * @param {MyWorkTaskIndexPayload} payload - The index payload can be used for the following resources.
   * @returns {MyWorkTaskSearchResult[]}
   */
  async searchTasks({ commit, rootState }, payload: MyWorkTaskIndexPayload) {
    try {
      const response = await rootState.api.get(`my-work/tasks/search?${queryFromObject(payload)}`, { withCredentials: true });

      return response.data as MyWorkTaskSearchResult[];
    } catch (e: any) {
      return e as ErrorResponse;
    }
  },

  /**
   * @description Index My work tasks that are due, best used with an assigned user and an activity Id.
   *
   * @param {MyWorkTaskIndexPayload} payload - The index payload can be used for the following resources.
   * @returns {MyWorkTask[]}
   */
  async indexDueTasks({ commit, rootState }, payload: MyWorkTaskIndexPayload) {
    try {
      const response = await rootState.api.get(`my-work/tasks/due?${queryFromObject(payload)}`, { withCredentials: true });

      return response.data as MyWorkTask[];
    } catch (e: any) {
      return e as ErrorResponse;
    }
  },

  /**
   * @description Index My work tasks for Today, best used with an assigned user and an activity Id.
   *
   * @param {MyWorkTaskIndexPayload} payload - The index payload can be used for the following resources.
   * @returns {MyWorkTask[]}
   */
  async indexTodayTasks({ commit, rootState }, payload: MyWorkTaskIndexPayload) {
    try {
      const response = await rootState.api.get(`my-work/tasks/today?${queryFromObject(payload)}`, { withCredentials: true });

      return response.data as MyWorkTask[];
    } catch (e: any) {
      return e as ErrorResponse;
    }
  },

  /**
   * @description Index My work tasks for the next 7 days, best used with an assigned user and an activity Id.
   *
   * @param {MyWorkTaskIndexPayload} payload - The index payload can be used for the following resources.
   * @returns {MyWorkTask[]}
   */
  async indexNextSevenDaysTasks({ commit, rootState }, payload: MyWorkTaskIndexPayload) {
    try {
      const response = await rootState.api.get(`my-work/tasks/7-days?${queryFromObject(payload)}`, { withCredentials: true });

      return response.data as MyWorkTask[];
    } catch (e: any) {
      return e as ErrorResponse;
    }
  },

  /**
   * @description Index My work tasks for the next 14 days, best used with an assigned user and an activity Id.
   *
   * @param {MyWorkTaskIndexPayload} payload - The index payload can be used for the following resources.
   * @returns {MyWorkTask[]}
   */
  async indexNextFourteenDaysTasks({ commit, rootState }, payload: MyWorkTaskIndexPayload) {
    try {
      const response = await rootState.api.get(`my-work/tasks/14-days?${queryFromObject(payload)}`, { withCredentials: true });

      return response.data as MyWorkTask[];
    } catch (e: any) {
      return e as ErrorResponse;
    }
  },

  /**
   * @description Index unscheduled My work tasks, best used with an assigned user and an activity Id.
   *
   * @param {MyWorkTaskIndexPayload} payload - The index payload can be used for the following resources.
   * @returns {MyWorkTask[]}
   */
  async indexUnscheduledTasks({ commit, rootState }, payload: MyWorkTaskIndexPayload) {
    try {
      const response = await rootState.api.get(`my-work/tasks/unscheduled?${queryFromObject(payload)}`, { withCredentials: true });

      return response.data as MyWorkTask[];
    } catch (e: any) {
      return e as ErrorResponse;
    }
  },

  /**
   * @description Index the activity labels for a given user, this can be used to add action labels to each activity.
   *
   * @param {MyWorkActivityIndexPayload} payload - The index payload can be used for the following resources.
   * @returns {MyWorkActivityActionLabel[]}
   */
  async indexMyWorkActivityActionLabels({ commit, rootState }, payload: MyWorkActivityIndexPayload & { merge?: boolean }) {
    try {
      const response = await rootState.api.post(`my-work/activities/labels`, payload, { withCredentials: true });

      if (payload?.merge) {
        commit("MERGE_ACTIVITY_LABELS", response.data);
      } else {
        commit("SET_ACTIVITY_LABELS", response.data);
      }

      return response.data as MyWorkActivityActionLabel[];
    } catch (e: any) {
      return e as ErrorResponse;
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
