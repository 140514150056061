import Vue from "vue";
import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";
import { parse } from "fecha";
import { ActivityAccount } from "@/store/modules/activity-account.store";

export interface IndexActivityValuesPayload {
  q?: {};
}

export interface ActivityValue {
  field: "property_type" | "property_address.zip" | "assigned_to.id";
  results: Array<{
    term: string;
    count: number;
  }>;
}

export interface IndexActivityPayload {
  q?: {
    office_id?: number[];
    and?: {
      and?: {
        or?: {
          work_status?: WorkStatus[];
        };
      };
      or?: {
        name?: string[] | string;
      };
    };
    or?: {
      name?: string[] | string;
    };
  };
  per_page?: number;
  after?: Array<string | number>;
  merge?: boolean;
}

export enum PropertyType {
  house = "HOUSE",
  appartment = "APPARTMENT",
  land = "LAND",
  office = "OFFICE",
  commercial = "COMMERCIAL",
  industrial = "INDUSTRIAL",
  parking = "PARKING",
}

export enum ActivityOrigin {
  AdCampaignEstimation = "AD_CAMPAIGN_ESTIMATION",
  AdCampaign001 = "AD_CAMPAIGN_001",
  AdCampaign002 = "AD_CAMPAIGN_002",
  AdCampaign003 = "AD_CAMPAIGN_003",
  Other = "OTHER",
  Client = "CLIENT",
  Purchase = "PURCHASE",
  Customers = "CUSTOMERS",
  Email = "EMAIL",
  Flyering = "FLYERING",
  Sign = "SIGN",
  Immoscoop = "IMMOSCOOP",
  Immovlan = "IMMOVLAN",
  Immoweb = "IMMOWEB",
  ColdLead = "COLD_LEAD",
  Surveyor = "SURVEYOR",
  MarketingPartner = "MARKETINGPARTNER",
  Employer = "EMPLOYER",
  LandLord = "LANDLORD",
  Socials = "SOCIALS",
  Portal = "PORTAL",
  Zimmo = "ZIMMO",
}

export enum WorkStatus {
  new = "NEW",
  contact = "CONTACT",
  estimate = "ESTIMATE",
  followUp = "FOLLOW_UP",
  followUpLT = "FOLLOW_UP_LT",
  won = "WON",
  lost = "LOST",
  newDossier = "NEW_DOSSIER",
  startUp = "STARTUP",
  published = "PUBLISHED",
  visits = "VISITS",
  firstOffer = "FIRST_OFFER",
  option = "OPTION",
  sold = "SOLD",
  deed = "DEED",
  done = "DONE",
  archived = "ARCHIVED",
  onHold = "ON_HOLD",
}

export enum SalesPromiseStatus {
  todo = "TODO",
  inProgress = "IN_PROGRESS",
  waiting = "WAITING",
  requested = "REQUESTED",
  approved = "APPROVED",
}
export enum NotaryAggrementStatus {
  blank = "BLANK",
  waiting = "WAITING",
  requested = "REQUESTED",
  approved = "APPROVED",
}

interface ActivityState {
  all: Activity[];
  values: ActivityValue[];
  total?: number;
  viewing?: Activity;
}

const initialState: ActivityState = {
  all: [],
  values: [],
  total: undefined,
  viewing: undefined,
};

const getters: GetterTree<ActivityState, RootState> = {
  all(state: ActivityState): Activity[] {
    return state.all;
  },
  values(state: ActivityState): ActivityValue[] {
    return state.values;
  },
  total(state: ActivityState): number | undefined {
    return state.total;
  },
  viewing(state: ActivityState): Activity | undefined {
    return state.viewing;
  },
};

const mutations: MutationTree<ActivityState> = {
  RESET(state: ActivityState) {
    state.all = [];
    state.viewing = undefined;
  },
  UPDATE_MODEL(state: ActivityState, payload: Activity) {
    const index = state.all.findIndex((model: Activity) => model.id === payload.id);

    if (index === -1) {
      state.all.push(mutateActivity(payload));
    } else {
      Vue.set(state.all, index, mutateActivity(payload));
    }

    if (state.viewing && state.viewing.id === payload.id) {
      state.viewing = mutateActivity(payload);
    }
  },
  DELETE_MODEL(state: ActivityState, id: number) {
    const index = state.all.findIndex((model: Activity) => model.id == id);

    if (index >= 0) {
      state.all.splice(index, 1);
    }

    if (state.viewing && state.viewing.id === id) {
      state.viewing = undefined;
    }
  },
  ADD_MODEL(state: ActivityState, payload: Activity) {
    state.all.push(mutateActivity(payload));
  },
  ADD_MODELS(state: ActivityState, payload: Activity[]) {
    payload.forEach((activity: Activity) => {
      state.all.push(mutateActivity(activity));
    });
  },
  SET_MODELS(state: ActivityState, payload: Activity[]) {
    state.all = payload
      .map((activity: Activity) => {
        return mutateActivity(activity);
      })
      .sort((a: any, b: any) => (a.action_status_priority > b.action_status_priority ? 1 : -1));
  },
  SET_VALUES(state: ActivityState, payload: ActivityValue[]) {
    state.values = payload;
  },
  SET_TOTAL(state: ActivityState, payload: number) {
    state.total = payload;
  },
  ADD_TOTAL(state: ActivityState, payload: number) {
    if (!state.total) {
      state.total = 0;
    }

    state.total = state.total + payload;
  },
  SET_VIEWING(state: ActivityState, payload: Activity) {
    state.viewing = payload;
  },
  ADD_ASSIGNED_TO(state: ActivityState, payload: ActivityAccount) {
    if (!state.viewing) {
      return;
    }

    state.viewing.assigned_to.push({
      relation_id: payload.id,
      id: payload.account_id,
      email: payload.email,
      first_name: payload.first_name,
      last_name: payload.last_name,
      phone: null,
      type: payload.type,
      media: payload.media,
    });
  },
  UPDATE_ASSIGNED_TO(state: ActivityState, payload: ActivityAccount) {
    if (!state.viewing) {
      return;
    }

    const index = state.viewing.assigned_to.findIndex((model: { relation_id: number }) => model.relation_id === payload.id);

    if (index === -1) {
      state.viewing.assigned_to.push({
        relation_id: payload.id,
        id: payload.account_id,
        email: payload.email,
        first_name: payload.first_name,
        last_name: payload.last_name,
        phone: null,
        type: payload.type,
        media: payload.media,
      });
    } else {
      Vue.set(state.viewing.assigned_to, index, {
        relation_id: payload.id,
        id: payload.account_id,
        email: payload.email,
        first_name: payload.first_name,
        last_name: payload.last_name,
        type: payload.type,
        media: payload.media,
      });
    }
  },
  DELETE_ASSIGNED_TO(state: ActivityState, payload: number) {
    if (!state.viewing) {
      return;
    }

    const index = state.viewing.assigned_to.findIndex((model: { relation_id: number }) => model.relation_id === payload);

    if (index >= 0) {
      state.viewing.assigned_to.splice(index, 1);
    }
  },
};

const actions: ActionTree<ActivityState, RootState> = {
  create({ commit, rootState }, payload: ActivityPayload) {
    return rootState.api
      .post(`activities`, payload, { withCredentials: true })
      .then((response: { data: Activity }) => {
        // @ts-ignore
        if (!payload.hasOwnProperty("dont_save") || !payload.dont_save) {
          commit("ADD_MODEL", response.data);
        }

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  patch({ commit, rootState }, payload: ActivityPatchPayload) {
    return rootState.api
      .patch(`activities/${payload.id}`, payload, { withCredentials: true })
      .then((response: { data: Activity }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  values({ commit, rootState }, payload: IndexActivityValuesPayload) {
    return rootState.api
      .get(`activity-values`, { withCredentials: true })
      .then((response: { data: ActivityValue[] }) => {
        commit("SET_VALUES", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  index({ commit, rootState }, payload: IndexActivityPayload) {
    return rootState.api
      .get(`activities?${payload ? queryFromObject(payload) : ""}`, { withCredentials: true })
      .then((response: { data: Activity[]; total: number }) => {
        if (payload.merge) {
          commit("ADD_MODELS", response.data);

          commit("ADD_TOTAL", response.total);
        } else {
          commit("SET_MODELS", response.data);

          commit("SET_TOTAL", response.total);
        }

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  read({ commit, rootState, dispatch }, payload: { id: number }) {
    return rootState.api
      .get(`activities/${payload.id}`, {
        withCredentials: true,
      })
      .then((response: { data: Activity }) => {
        commit("UPDATE_MODEL", response.data);

        commit("SET_VIEWING", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  readPublic({ commit, rootState }, public_id: string) {
    return rootState.api
      .get(`public-activities/${public_id}`)
      .then((response: { data: Activity }) => {
        commit("UPDATE_MODEL", response.data);

        commit("SET_VIEWING", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  requestBidEmail({ rootState }, payload: { id: number; email: string }) {
    return rootState.api
      .post(`activities/${payload.id}/request-bid-email`, payload, { withCredentials: true })
      .then((response: { data: void }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  publishMedia({ rootState }, payload: { id: number; document_ids: number[] }) {
    return rootState.api
      .post(`activities/${payload.id}/media/publish`, payload, { withCredentials: true })
      .then((response: { data: void }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  delete({ commit, rootState }, id: number) {
    return rootState.api
      .delete(`activities/${id}`, { withCredentials: true })
      .then(() => {
        commit("DELETE_MODEL", id);

        return Promise.resolve();
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  setViewing({ commit }, payload: Activity) {
    commit("UPDATE_MODEL", payload);
    commit("SET_VIEWING", payload);
  },
  clear({ commit }) {
    commit("SET_MODELS", []);
    commit("SET_VIEWING", null);
  },
  clearViewing({ commit }) {
    commit("SET_VIEWING", null);
  },
};

const mutateActivity = (activity: Activity) => {
  if (!activity.actions) {
    return activity;
  }

  activity.actions = activity.actions.map((action) => {
    let state = "";
    if (new Date(action.deadline).getDate() === new Date().getDate()) {
      state = "today";
    } else if (parse(action.deadline, "isoDateTime")! < new Date() && action.type === "task") {
      state = "overdue";
    } else if (parse(action.deadline, "isoDateTime")! > new Date()) {
      state = "future";
    }

    return { ...action, state };
  });

  if (activity.actions.findIndex((action) => action.state === "overdue") > -1) {
    activity.action_status_priority = 1;
  } else if (activity.actions.findIndex((action) => action.state === "today") > -1) {
    activity.action_status_priority = 3;
  } else if (activity.actions.filter((action) => (action.type === "appointment" && action.state === "future") || action.type === "task").length === 0) {
    activity.action_status_priority = 2;
  } else {
    activity.action_status_priority = 4;
  }

  return activity;
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
