import { ActionTree, MutationTree, GetterTree, Payload } from "vuex";
import Vue from "vue";

const initialState: ContactState = {
    sellers: [],
    team_members: [],
};

const getters: GetterTree<ContactState, RootState> = {
    sellers(state: ContactState): Contact[] {
        return state.sellers;
    },
    team_members(state: ContactState): Contact[] {
        return state.team_members;
    },
};

const mutations: MutationTree<ContactState> = {
    RESET(state: ContactState) {
        state.sellers = [];
        state.team_members = [];
    },
    SET_SELLERS(state: ContactState, payload: Contact[]) {
        state.sellers = payload;
    },
    SET_TEAM_MEMBERS(state: ContactState, payload: Contact[]) {
        state.team_members = payload;
    },
    ADD_TEAM_MEMBER(state: ContactState, contact: Contact) {
        const index = state.team_members.findIndex((model: Contact) => model.id === contact.id);

        if (index === -1) {
            state.team_members.push(contact);
        } else {
            Vue.set(state.team_members, index, contact);
        }
    },
    DELETE_TEAM_MEMBER(state: ContactState, account_id: number) {
        state.team_members = state.team_members.filter((contact: Contact) => contact.id !== account_id);
    },
};

const actions: ActionTree<ContactState, RootState> = {
    indexSellers({ commit, rootState }, payload: { query?: string; activity_id: number; after?: Array<string> }) {
        const params = new URLSearchParams();

        if (payload) {
            if (payload.after && payload.after !== null) {
                payload.after.forEach((a: string) => {
                    params.append("after[]", a);
                });
            }
        }

        params.append("q[and][roles]", "seller");
        params.append("s[id]", "asc");
        params.append("per_page", "500");

        const paramsString = decodeURIComponent(params.toString());
        return rootState.api
            .get(`activities/${payload.activity_id}/accounts?${paramsString}`, {
                withCredentials: true,
            })
            .then((response: { data: Contact[]; total: number }) => {
                if (payload && payload.after && payload.after !== null) {
                    commit("ADD_SELLERS", response.data);
                } else {
                    commit("SET_SELLERS", response.data);
                }

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    indexTeamMembers({ commit, rootState }, payload: { query?: string; activity_id: number; after?: Array<string> }) {
        const params = new URLSearchParams();

        if (payload) {
            if (payload.after && payload.after !== null) {
                payload.after.forEach((a: string) => {
                    params.append("after[]", a);
                });
            }
        }

        params.append("q[and][or][roles]", "user");
        params.append("q[and][or][roles]", "administrator");
        params.append("s[id]", "asc");
        params.append("per_page", "500");

        const paramsString = decodeURIComponent(params.toString());
        return rootState.api
            .get(`activities/${payload.activity_id}/accounts?${paramsString}`, {
                withCredentials: true,
            })
            .then((response: { data: Contact[]; total: number }) => {
                if (payload && payload.after && payload.after !== null) {
                    commit("ADD_TEAM_MEMBERS", response.data);
                } else {
                    commit("SET_TEAM_MEMBERS", response.data);
                }

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    joinTeam({ commit, rootState }, payload: {  account: Account; activity_id: number; notify: boolean }) {
        commit("ADD_TEAM_MEMBER", {
            id: payload.account.id,
            first_name: payload.account.first_name,
            last_name: payload.account.last_name,
            notify: payload.notify,
        });

        const simple_payload = {
            account_id: payload.account.id,
            notify: payload.notify,
        };

        return rootState.api
            .post(`activities/${payload.activity_id}/accounts`, simple_payload, {
                withCredentials: true,
            })
            .then((response: { data: Contact }) => {
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    leaveTeam({ commit, rootState }, payload: { account_id: number; activity_id: number }) {
        commit("DELETE_TEAM_MEMBER", payload.account_id);

        return rootState.api
            .delete(`activities/${payload.activity_id}/accounts/${payload.account_id}`, {
                withCredentials: true,
            })
            .then((response: { data: Contact }) => {
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    clear({ commit }) {
        commit("SET_SELLERS", []);
        commit("SET_TEAM_MEMBERS", []);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
