import { ActionTree, MutationTree, GetterTree } from "vuex";

const initialState: PropertyState = {
  viewing: undefined,
};

const getters: GetterTree<PropertyState, RootState> = {
  viewing(state: PropertyState): Property | undefined {
    return state.viewing;
  },
};

const mutations: MutationTree<PropertyState> = {
  SET_VIEWING(state: PropertyState, payload: Property) {
    state.viewing = payload;
  },
  RESET(state: PropertyState) {
    state.viewing = undefined;
  },
};

const actions: ActionTree<PropertyState, RootState> = {
  create({ rootState }, payload: PropertyCreatePayload) {
    return rootState.api
      .post(`properties`, payload, {
        withCredentials: true,
      })
      .then((response: { data: Property }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  update({ commit, rootState }, payload: PropertyUpdatePayload) {
    return rootState.api
      .put(`properties/${payload.property_id}`, payload, {
        withCredentials: true,
      })
      .then((response: { data: Property }) => {
        commit("SET_VIEWING", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  patch({ commit, rootState }, payload: PropertyPatchPayload) {
    return rootState.api
      .patch(`properties/${payload.property_id}`, payload, { withCredentials: true })
      .then((response: { data: Property }) => {
        commit("SET_VIEWING", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  patchPrice({ commit, rootState }, { property_id, payload }: any) {
    return rootState.api
      .patch(`properties/${property_id}/price`, payload, { withCredentials: true })
      .then((response: { data: Property }) => {
        commit("SET_VIEWING", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  read({ commit, rootState }, payload: { activity_id: number }) {
    const params = new URLSearchParams();

    if (payload) {
      if (payload.activity_id) {
        params.append("q[and][or][activity_id][]", payload.activity_id.toString());
      }
    }

    const paramsString = decodeURIComponent(params.toString());

    return rootState.api
      .get(`properties?${paramsString}`, {
        withCredentials: true,
      })
      .then((response: { data: Property[] }) => {
        if (response.data.length > 0) {
          commit("SET_VIEWING", response.data[0]);
          return Promise.resolve(response.data[0]);
        }
        return Promise.resolve(null);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  setViewing({ commit }, payload: Property) {
    commit("SET_VIEWING", payload);
  },

  clear({ commit }) {
    commit("SET_VIEWING", null);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
