import Vue from "vue";
import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";
import { WorkStatus } from "./activity.store";
import { PartnerType } from "./partner.store";
import { TeamFunctions } from "./activity-account.store";
import { PickupKeyPayload } from "@/mixins/partnerRequests/has-pickup-keys";

export enum PartnerRequestStatus {
  new = "NEW",
  planned = "PLANNED",
  done = "DONE",
}

export interface PartnerRequestServiceAddon {
  title: string;
}

export interface PartnerRequestService {
  title: string;
  addons?: PartnerRequestServiceAddon[];
}

export interface CreatePartnerRequestPayload extends PickupKeyPayload {
  partner_id: number;
  activity_id: number;
  note?: string | null;
  services: PartnerRequestService[];
  planned_at: string | null;
  day_period: "AM" | "PM" | "FULL" | null;
  status: PartnerRequestStatus;
}

export interface PatchPartnerRequestPayload {
  id: number;
  status?: PartnerRequestStatus;
  partner_id?: number;
  activity_id?: number;
  note?: string | null;
  services?: PartnerRequestService[];
  planned_at?: string | null;
  day_period?: "AM" | "PM" | "FULL" | null;
}

export interface PartnerRequestValue {
  field: string;
  results: Array<{
    term: string;
    count: number;
  }>;
}

export interface PartnerRequest {
  id: number;
  status: PartnerRequestStatus;
  partner: {
    id: number;
    company_name: string;
    type: PartnerType;
  };
  activity: {
    id: number;
    name: string;
    property_address: Address | null;
    office: {
      id: number;
      name: string;
    };
    assigned_to: Array<{
      id: number;
      first_name: string;
      last_name: string | null;
      email: string;
      whise_id: number | null;
      phone: string | null;
      media: Media | null;
      type: TeamFunctions;
    }> | null;
    contacts: Array<{
      id: number;
      first_name: string;
      last_name: string | null;
      email: string;
      phone: string | null;
    }> | null;
  };
  note: string | null;
  services: PartnerRequestService[];
  planned_at: string | null;
  created_at: string;
  day_period: "AM" | "PM" | "FULL" | null;
  sort?: Array<string | number>;
}

interface PartnerRequestState {
  all: PartnerRequest[];
  viewing?: PartnerRequest;
  values: PartnerRequestValue[];
}

const initialState: PartnerRequestState = {
  all: [],
  values: [],
  viewing: undefined,
};

const getters: GetterTree<PartnerRequestState, RootState> = {
  all(state: PartnerRequestState): PartnerRequest[] {
    return state.all;
  },
  viewing(state: PartnerRequestState): PartnerRequest | undefined {
    return state.viewing;
  },
  values(state: PartnerRequestState): PartnerRequestValue[] {
    return state.values;
  },
};

const mutations: MutationTree<PartnerRequestState> = {
  RESET(state: PartnerRequestState) {
    state.all = [];
  },
  ADD_MODEL(state: PartnerRequestState, payload: PartnerRequest) {
    state.all.push(payload);
  },
  SET_MODELS(state: PartnerRequestState, payload: PartnerRequest[]) {
    state.all = payload;
  },
  SET_VIEWING(state: PartnerRequestState, payload: PartnerRequest) {
    state.viewing = payload;
  },
  ADD_MODELS(state: PartnerRequestState, payload: PartnerRequest[]) {
    state.all = [...state.all, ...payload];
  },
  SET_VALUES(state: PartnerRequestState, payload: PartnerRequestValue[]) {
    state.values = payload;
  },
  UPDATE_MODEL(state: PartnerRequestState, payload: PartnerRequest) {
    const index = state.all.findIndex((model: PartnerRequest) => model.id === payload.id);
    if (index === -1) {
      state.all.push(payload);
    } else {
      Vue.set(state.all, index, payload);
    }
    return;
  },
  DELETE_MODEL(state: PartnerRequestState, id: number) {
    // @ts-ignore
    const index = state.all.findIndex((model: PartnerRequest) => model.id == id);

    if (index >= 0) {
      state.all.splice(index, 1);
    }
  },
};

const actions: ActionTree<PartnerRequestState, RootState> = {
  index({ commit, rootState }, payload) {
    return rootState.api
      .get(`partner-requests?${payload ? queryFromObject(payload) : ""}`, { withCredentials: true })
      .then((response: { data: PartnerRequest[]; total: number }) => {
        if (!payload.skip_mutation) {
          commit("SET_MODELS", response.data);
        }

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  read({ commit, rootState }, payload: number) {
    return rootState.api
      .get(`partner-requests/${payload}`, { withCredentials: true })
      .then((response: { data: PartnerRequest }) => {
        commit("SET_VIEWING", response.data);
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  create({ commit, rootState }, payload: CreatePartnerRequestPayload) {
    return rootState.api
      .post(`partner-requests`, payload, { withCredentials: true })
      .then((response: { data: PartnerRequest }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  patch({ commit, rootState }, payload: PatchPartnerRequestPayload) {
    return rootState.api
      .patch(`partner-requests/${payload.id}`, payload, { withCredentials: true })
      .then((response: { data: PartnerRequest }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  patchStatus({ commit, rootState }, payload: PatchPartnerRequestPayload) {
    return rootState.api
      .patch(`partner-requests/${payload.id}/status`, payload, { withCredentials: true })
      .then((response: { data: PartnerRequest }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  values({ commit, rootState }, payload) {
    return rootState.api
      .get(`partner-request-values`, { withCredentials: true })
      .then((response: { data: PartnerRequestValue[] }) => {
        commit("SET_VALUES", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  delete({ commit, rootState }, payload: number) {
    return rootState.api
      .delete(`partner-requests/${payload}`, { withCredentials: true })
      .then((response: { data: void }) => {
        commit("DELETE_MODEL", payload);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
