import store from "@/store/index";

import { ActivitySeller } from "@/store/modules/activity-seller.store";

/**
 * Guard redirectIfLoggedIn middleware
 * Redirect to the login page when the user is not logged in
 *
 * @param {to} string The route the user was comming from
 * @param {from} string The route the user is going to
 * @param {next} function The next route funciton
 */
export default async function guardRedirectIfLoggedIn(to: any, from: any, next: any): Promise<void> {
    let auth: CurrentAccount | null = null;

    try {
        auth = await store.dispatch("auth/me");

        if (!auth) {
            throw new Error("No auth");
        }

        let role = auth.getRole();

        if (role === "employee") {
            return next({ name: "sales-pipeline" });
        }

        if (role === "partner") {
            return next({ name: "partner-dashboard" });
        }

        if (role === "seller") {
            const activitySellers: ActivitySeller[] = await store.dispatch("activity-seller/index");
            
            if (activitySellers.length === 0 || !auth.seller_id) {
                return next({ name: "error", params: { code: "404" } });
            }

            const activitySellersWithDashboardAccess = activitySellers.filter((as) => as.dashboard_access && as.seller_id === auth!.seller_id);

            if (activitySellersWithDashboardAccess.length === 0) {
                return next({ name: "error", params: { code: "404" } });
            }

            return next({ name: "seller-timeline", params: { id: activitySellersWithDashboardAccess[0].activity_id } });
        }
    } catch (e) {
        console.error(e);
        if (auth) {
            console.log("LOGOUT!!");
            // return next({ name: "logout" });
        } else {
            return next({ name: "login" });
        }
    }
}
