var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ValidationProvider',{ref:"provider",class:{ 'opacity-75 cursor-not-allowed': _vm.disabled },attrs:{"mode":"eager","tag":"div","vid":_vm.name,"name":_vm.validateAs,"rules":_vm.rules,"bails":false},scopedSlots:_vm._u([{key:"default",fn:function({ classes, errors }){return [_c('div',{staticClass:"flex flex-row items-center justify-between gap-4"},[(_vm.label)?_c('label',{staticClass:"block w-full",class:[classes],attrs:{"for":`id_${_vm.name}`}},[_vm._v(" "+_vm._s(_vm.label)+" "),((_vm.rules && typeof _vm.rules === 'string' && _vm.rules.includes('required')) || (typeof _vm.rules === 'object' && _vm.rules.required))?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"flex flex-row flex-none gap-4 select-none"},[_c('div',{staticClass:"subtract bg-gray-200 h-[32px] w-[32px] rounded-full flex items-center justify-center cursor-pointer hover:scale-90 transition-transform",on:{"click":function($event){$event.preventDefault();return _vm.handleMin()}}},[_c('icon-minus',{staticClass:"w-[8px] stroke-2 stroke-black"})],1),_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],ref:"input",class:[
          classes,
          'w-full focus:outline-none flex items-center transition ease-in-out',
          {
            '!border-gray-300': _vm.disabled,
            '!border-red': errors[0],
          },
        ],attrs:{"autocomplete":"false","min":"0","type":"number","id":`id_${_vm.name}`,"placeholder":_vm.placeholder,"aria-describedby":`description_${_vm.name}`},domProps:{"value":(_vm.localValue)},on:{"input":function($event){if($event.target.composing)return;_vm.localValue=$event.target.value}}},{ ..._vm.$listeners, input: _vm.handleInput })),_c('div',{staticClass:"add bg-gray-200 h-[32px] w-[32px] rounded-full flex items-center justify-center cursor-pointer hover:scale-90 transition-transform",on:{"click":function($event){$event.preventDefault();return _vm.handlePlus()}}},[_c('icon-plus',{staticClass:"h-[8px] stroke-2 stroke-black"})],1)])]),(_vm.showError && errors[0])?_c('span',{staticClass:"text-red text-12 block mt-4"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }