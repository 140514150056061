import { ActionTree, MutationTree, GetterTree } from "vuex";

const initialState: TempMediaState = {
    media: [],
    uploading: false,
    total: 0,
    uploaded: 0,
    errors: [],
};

const getters: GetterTree<TempMediaState, RootState> = {
    media(state: TempMediaState): Media[] {
        return state.media;
    },
    uploading(state: TempMediaState): boolean {
        return state.uploading;
    },
    total(state: TempMediaState): number {
        return state.total;
    },
    uploaded(state: TempMediaState): number {
        return state.uploaded;
    },
    errors(state: TempMediaState): Array<{}> {
        return state.errors;
    },
};

const mutations: MutationTree<TempMediaState> = {
    RESET(state: TempMediaState) {
        state.media = [];
        state.total = 0;
        state.uploaded = 0;
        state.errors = [];
        console.log("reset media");
    },
    SET_MEDIA(state: TempMediaState, payload: Media) {
        state.media = [payload];
    },
    ADD_MEDIA(state: TempMediaState, payload: Media) {
        state.media?.push(payload);
    },
    SET_UPLOAD_STATUS(state: TempMediaState, payload: boolean) {
        state.uploading = payload;
        console.log("Uploadstate: %s", state.uploading);
    },
    SET_TOTAL(state: TempMediaState, payload: number) {
        state.total = payload;
        console.log("Set total: ", state.total);
    },
    SET_UPLOADED(state: TempMediaState, payload: number) {
        state.uploaded = payload;
    },
    INCREMENT_UPLOADED(state: TempMediaState) {
        state.uploaded = state.uploaded + 1;
        console.log("Set uploaded: ", state.uploaded);
    },
    SET_ERRORS(state: TempMediaState, payload: Array<{}>) {
        state.errors = payload;
    },
    ADD_ERROR(state: TempMediaState, payload: any) {
        state.errors.push(payload);
    },
};

const actions: ActionTree<TempMediaState, RootState> = {
    upload({ rootState, commit }, payload: TempMediaUploadPayload) {
        const formData = new FormData();
        formData.append("name", payload.name);
        formData.append("file", payload.file);
        formData.append("uuid", payload.uuid);
        return rootState.api
            .post("media-library-pro/uploads", formData, { withCredentials: true })
            .then((response: Media) => {
                if (!payload.skip_mutation) {
                    commit("ADD_MEDIA", { ...response, type: payload.type, folder: payload.folder });
                }
                return Promise.resolve(response);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },

    startUploading({ commit }) {
        commit("SET_UPLOAD_STATUS", true);
    },

    endUploading({ commit }) {
        commit("SET_UPLOAD_STATUS", false);
    },

    setTotal({ commit }, total: number) {
        commit("SET_TOTAL", total);
    },

    setUploaded({ commit }, total: number) {
        commit("SET_UPLOADED", false);
    },

    incrementUploaded({ commit }) {
        commit("INCREMENT_UPLOADED");
    },

    setErrors({ commit }, errors: Array<{}>) {
        commit("SET_ERRORS", errors);
    },

    addError({ commit }, error: any) {
        commit("ADD_ERROR", error);
    },

    reset({ commit }) {
        commit("RESET");
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
