import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";
import Vue from "vue";

/** Interfaces */
export interface VastgoedexpertsProduct {
  id: number;
  partner_id: number;
  activity_id: number;
  status: string;
  type: string;
  url: string;
  created_at: string;
  updated_at: string;
}

export interface VastgoedexpertsLog {
  id: number;
  status: string;
  log: string;
  created_at: string;
  updated_at: string;
}

export interface VastgoedexpertsFile {
  id: number;
  products: VastgoedexpertsProduct[];
  logs: VastgoedexpertsLog[];
  created_at: string;
  updated_at: string;
}

export interface ImmoSignLayout {
  name: string;
  layout_id: number;
  opdruk?: string;
  type?: string;
  formaat?: string;
  img_url?: string;
  stock?: number;
  vestiging_id?: number;
}

interface VastgoedexpertsState {
  all: VastgoedexpertsProduct[];
}

const initialState: VastgoedexpertsState = {
  all: [],
};

const getters: GetterTree<VastgoedexpertsState, RootState> = {
  all(state: VastgoedexpertsState): VastgoedexpertsProduct[] {
    return state.all;
  },
};

const mutations: MutationTree<VastgoedexpertsState> = {
  RESET(state: VastgoedexpertsState) {
    state.all = [];
  },
  ADD_MODEL(state: VastgoedexpertsState, payload: VastgoedexpertsProduct) {
    state.all.unshift(payload);
  },
  SET_MODELS(state: VastgoedexpertsState, payload: VastgoedexpertsProduct[]) {
    state.all = payload;
  },
  UPDATE_MODEL(state: VastgoedexpertsState, payload: VastgoedexpertsProduct) {
    const index = state.all.findIndex((model: VastgoedexpertsProduct) => model.id === payload.id);

    if (index === -1) {
      state.all.push(payload);
    } else {
      Vue.set(state.all, index, payload);
    }
  },
};

const actions: ActionTree<VastgoedexpertsState, RootState> = {
  createEpc({ commit, rootState }, payload: any) {
    return rootState.api
      .post(`vastgoedexperts/epc`, payload, { withCredentials: true })
      .then((response: { data: any }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  createEk({ commit, rootState }, payload: any) {
    return rootState.api
      .post(`vastgoedexperts/ek`, payload, { withCredentials: true })
      .then((response: { data: any }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  createFueltank({ commit, rootState }, payload: any) {
    return rootState.api
      .post(`vastgoedexperts/fueltank`, payload, { withCredentials: true })
      .then((response: { data: any }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  createAsbestos({ commit, rootState }, payload: any) {
    return rootState.api
      .post(`vastgoedexperts/asbestos`, payload, { withCredentials: true })
      .then((response: { data: any }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  createImmoSigns({ commit, rootState }, payload: any) {
    return rootState.api
      .post(`vastgoedexperts/immo-signs`, payload, { withCredentials: true })
      .then((response: { data: any }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  updateImmoSigns({ commit, rootState }, payload: any) {
    return rootState.api
      .put(`vastgoedexperts/immo-signs`, payload, { withCredentials: true })
      .then((response: { data: any }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  readStatus({ commit, rootState }, payload: { activity_id: number; partner_id: number }) {
    return rootState.api
      .get(`activities/${payload.activity_id}/partners/${payload.partner_id}/vge-status`, { withCredentials: true })
      .then((response: { data: VastgoedexpertsProduct[] }) => {
        response.data.forEach((vastgoedexpertsProduct) => {
          commit("UPDATE_MODEL", vastgoedexpertsProduct);
        });

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  indexLogs({ commit, rootState }, payload: { activity_id: number }) {
    return rootState.api
      .get(`activities/${payload.activity_id}/vge-logs`, { withCredentials: true })
      .then((response: { data: VastgoedexpertsLog[] }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  indexLogsForPartner({ commit, rootState }, payload: { activity_id: number; partner_id: number }) {
    return rootState.api
      .get(`activities/${payload.activity_id}/partners/${payload.partner_id}/vge-logs`, { withCredentials: true })
      .then((response: { data: VastgoedexpertsLog[] }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  readImmoSignLayouts({ commit, rootState }, activity_id: number) {
    return rootState.api
      .get(`activities/${activity_id}/vge-immo-sign-layouts`, { withCredentials: true })
      .then((response: { data: ImmoSignLayout[] }) => {
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
