import { render, staticRenderFns } from "./file.vue?vue&type=template&id=0b5323fc&scoped=true"
import script from "./file.vue?vue&type=script&lang=ts"
export * from "./file.vue?vue&type=script&lang=ts"
import style0 from "./file.vue?vue&type=style&index=0&id=0b5323fc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b5323fc",
  null
  
)

export default component.exports