import store from "@/store/index";

/**
 * Guard guest middleware
 * Redirect to the login page when the user is not logged in
 *
 * @param {to} string The route the user was comming from
 * @param {from} string The route the user is going to
 * @param {next} function The next route funciton
 */
export default async function guardAuth(to: any, from: any, next: any): Promise<void> {
    if (!to.meta.group) {
        console.error("No group assigned to route: ", to);
        return next({ name: "error", params: { code: "422" } });
    }

    if (!Array.isArray(to.meta.group)) {
        console.error("To meta group is not an Array", to);
        return next({ name: "error", params: { code: "422" } });
    }

    try {
        const auth: CurrentAccount | undefined = await store.dispatch("auth/me");

        if (!auth) {
            throw new Error("No auth");
        }

        let role = auth.getRole();

        if (!to.meta.group.includes(role)) {
            console.error("Not allowed to navigate to %s as %s", to.name, role);
            return next({ name: "home" });
        }

        return next();
    } catch (e) {
        console.error(e);
        if (to.meta.group.includes("guest") && !to.meta.group.includes("auth")) {
            return next();
        }
        return next({ name: "login" });
    }
}

