
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({
    components: {
        ValidationProvider,
    },
})
export default class FormRadio extends Vue {
    @Prop() value!: boolean;

    @Prop({ default: "default" }) theme!: string;

    @Prop() values!: Array<{ label: string; value: boolean }>;

    @Prop() name!: string;

    @Prop() label!: string;

    @Prop({ default: false }) disabled?: boolean;

    @Prop({default: false}) fakeRequired!: boolean;

    @Prop() rules?: string | object;

    localValue: boolean = false;

    handleClick(value: boolean) {
        this.localValue = value;
        this.$emit("input", this.localValue);
    }

    @Watch("value", { immediate: true })
    private valueChanged(newValue: boolean) {
        this.localValue = newValue;
    }
}
