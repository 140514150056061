import guardAuth from "@/router/middleware/auth";
import { loadView } from "@/router/helpers";

const routes = [
    {
        path: "/mijn-werk",
        name: "my-work",
        component: loadView("auth/my-work/index"),
        beforeEnter: guardAuth,
        meta: {
            title: "Mijn Werk | Hubr",
            section: "auth",
            group: ["employee"],
        },
    },
];

export default routes;
