
import { ValidationProvider } from "vee-validate";
import { Component, Vue, Prop, Emit, Watch, Ref } from "vue-property-decorator";
import { VueTelInput } from "vue-tel-input";

@Component({
    components: {
        ValidationProvider,
        VueTelInput,
    },
})
export default class FormPhone extends Vue {
    @Prop() name?: string;

    @Prop() value?: string;

    @Prop() placeholder?: string;

    @Prop() rules?: string | object;

    @Prop() label?: string;

    @Prop({ default: () => [{ name: "België", iso2: "BE", dialCode: "32" }] }) countries?: Array<{ name: string; iso2: string; dialCode: string }>;

    @Prop({ default: true }) showError?: boolean;

    @Prop({ default: false }) disabled!: boolean;

    @Prop({ default: false }) autoFocus!: boolean;

    @Prop({ default: "text-12" }) labelClass!: string;

    @Ref() input!: HTMLInputElement;

    @Ref() readonly provider!: InstanceType<typeof ValidationProvider>;

    isFocussed = false;

    localValue = "";

    validAtleastOnce = false;

    eagerIfDirty(_a: { flags: any; errors: any }) {
        var errors = _a.errors;
        var flags = _a.flags;
        if (errors.length) {
            return {
                on: ["input", "change"],
            };
        }

        if (flags.valid && !this.validAtleastOnce) {
            this.validAtleastOnce = true;
        }

        if (flags.dirty) {
            return {
                on: ["change", "blur", "input"],
            };
        }

        return {
            on: ["change"],
        };
    }

    handlePhoneChange() {
        this.handleInput();
    }

    mounted() {
        if (this.autoFocus) {
            this.input.focus();
        }

        this.provider.syncValue(this.localValue);
    }

    get formValdiated(): boolean {
        return this.provider.$_veeObserver?.flags?.validated;
    }

    get validateAs(): string {
        if (this.label) {
            return this.label;
        }

        if (this.placeholder) {
            return this.placeholder;
        }

        if (this.name) {
            return this.name;
        }

        return `phone field`;
    }

    @Emit("input")
    handleInput() {
        this.provider.syncValue(this.localValue);

        return this.localValue;
    }

    @Emit("blur")
    handleBlur(e: Event): Event {
        if (this.provider && this.provider.flags.dirty) {
            this.validAtleastOnce = true;
        }
        this.isFocussed = false;
        return e;
    }

    @Emit("focus")
    handleFocus(e: Event): Event {
        this.isFocussed = true;
        return e;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: string | null) {
        this.localValue = (newValue ?? "").replace(/ /g, "");
    }
}
