import { format, parse, setGlobalDateI18n } from "fecha";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { i18n } from "@/localization";

import TimeAgo from "javascript-time-ago";
import nl from "javascript-time-ago/locale/nl";
TimeAgo.addDefaultLocale(nl);

const timeAgo = new TimeAgo("nl-NL");
const textMaskCore = require("text-mask-core");
const currencyMask = createNumberMask({
  prefix: "€ ",
  allowDecimal: true,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  decimalSymbol: ",",
  allowNegative: false,
});

setGlobalDateI18n({
  dayNamesShort: ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"],
  dayNames: ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"],
  monthNamesShort: ["jan.", "feb.", "mrt.", "apr.", "mei", "jun.", "jul.", "aug.", "sep.", "oct.", "nov.", "dec."],
  monthNames: ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"],
  amPm: ["am", "pm"],
  DoFn: function (D) {
    // @ts-ignore
    return D + ["ste", "de", "de", "de"][D % 10 > 3 ? 0 : ((D - (D % 10) !== 10) * D) % 10];
  },
});

function formatCurrency(amount: number | string) {
  if (typeof amount === "string") {
    amount = Number(amount.replace(".", ""));
  }
  const currencyAmount = amount.toFixed(2).replace(".", ",");

  return textMaskCore.conformToMask(currencyAmount, currencyMask).conformedValue;
}

function formatDate(dateString: string, f?: string) {
  return format(parse(dateString, "isoDateTime") as Date, f ?? "DD-MM-YYYY [om] HH:mm");
}

function formatErrors(e: any): ErrorResponse {
  //TODO: Create case logic for all types of errors
  if (e instanceof Error) {
    console.error(e);
    return { status: 500, errors: [{ message: "something went wrong" }] };
  }

  if (e.hasOwnProperty("errors") && e.errors.length > 0) {
    e.errors = e.errors.map((error: any) => {
      let field = error.field ?? '';
        
      if (field.includes("characteristics")) {
        field = i18n.t(`property.${field.replace("characteristics.", "form.titles.")}`);
      }

      let message: string | boolean = false;
      if (i18n.te(`errors.${error.message}`)) {
        message = i18n.t(`errors.${error.message}`, [field]) as string;
      }

      return { ...error, localizedMessage: message };
    });
  }

  return e;
}

function formatTimeAgo(time: string) {
  return timeAgo.format(new Date(time));
}

interface ToolTip {
  title?: string;
  description?: string;
  deadline?: string;
  class?: "small-tooltip" | "large-tooltip";
  type?: "task" | "appointment" | "other";
}
function formatTooltip(object?: ToolTip | false) {
  if (!object || !object.title) {
    return;
  }

  let html = `<div class="small-tooltip">`;

  if (object.type === "task") {
    html += `<div class="small-tooltip-title">Taak</div>`;
  }

  if (object.type === "appointment") {
    html += `<div class="small-tooltip-title">Afspraak</div>`;
  }

  if (object.title) {
    html += `<div class="small-tooltip-content">${object.title}</div>`;
  }

  if (object.description) {
    html += `<div class="small-tooltip-description">${object.description}</div>`;
  }

  if (object.deadline && object.type === "task") {
    html += `<div class="small-tooltip-footer">${formatDate(object.deadline, "dddd D MMMM YYYY")}</div>`;
  }

  if (object.deadline && object.type === "appointment") {
    html += `<div class="small-tooltip-footer">${formatDate(object.deadline, "dddd D MMMM YYYY [om] HH:mm")}</div>`;
  }

  html += "</div>";
  return {
    content: html,
    html: true,
    classes: [object.class ?? "small-tooltip"],
    class: [object.class ?? "small-tooltip"],
    offset: [0, 12],
    delay: { show: 0, hide: 300 },
    placement: "right",
  };
}

export { formatCurrency, formatDate, formatErrors, formatTimeAgo, formatTooltip };
