import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";
import Vue from "vue";

export interface IndexDocumentPayload {
  q?: {
    and: {
      activity_id?: number | number[];
      partner_id?: number | number[];
      name?: string;
      or?: {
        type?: DocumentType | DocumentType[];
      };
    };
  };
  per_page?: number;
}

export interface PatchDocumentPayload {
  id: number;
  type?: DocumentType | null;
  meta?: {
    folder: DocumentFolder | null;
    access: DocumentAccess[];
  } | null;
}

export enum ConnectivePackageStatus {
  draft = "DRAFT",
  pending = "PENDING",
  inProgress = "IN_PROGRESS",
  ending = "ENDING",
  finished = "FINISHED",
  archived = "ARCHIVED",
  rejected = "REJECTED",
  revoked = "REVOKED",
  expired = "EXPIRED",
  failed = "FAILED",
  fullySigned = "FULLY_SIGNED",
  pendingSigning = "PENDING_SIGNING",
}

export enum DocumentFolder {
  mediationAgreement = "MEDIATION_AGREEMENT",
  legalProvisions = "LEGAL_PROVISIONS",
  inspections = "INSPECTIONS",
  photos = "PHOTOS",
  salesAgreement = "SALES_AGREEMENT",
  varia = "VARIA",
  invoice = "INVOICE",
  syndic = "SYNDIC",
  advisuersfiche = "ADVISUERFICHE",
  board = "BOARD",
  pictures = "PICTURES",
  measurementPlan = "MEASUREMENT_PLAN",
  socialMedia = "SOCIAL_MEDIA",
  openSalesAgreement = "OPEN_SALES_AGREEMENT",
  closedSalesAgreement = "CLOSED_SALES_AGREEMENT",
  approvedSalesAgreement = "APPROVED_SALES_AGREEMENT",
  salesAgreementSub = "SALES_AGREEMENT_SUB",

  vge = "VGE",
  vgeEpc = "VGE_EPC",
  vgeEk = "VGE_EK",
  vgeAsbestos = "VGE_ASBESTOS",
  vgeImmoSigns = "VGE_IMMO_SIGNS",

  asbestosProject = "ASBESTOS_PROJECT",

  otherInspections = "OTHER_INSPECTIONS",
}

export enum DocumentType {
  eigendomstitel = "EIGENDOMSTITEL",
  ovam = "OVAM",
  epc = "EPC",
  bemiddelingsOvereenkomst = "BEMIDDELINGS_OVEREENKOMST",
  precontractueleChecklist = "PRECONTRACTUELE_CHECKLIST",
  stedenbouw = "STEDENBOUW",
  rooilijnplan = "ROOILIJNPLAN",
  kadastraalPlan = "KADASTRAAL_PLAN",
  kasatraleLegger = "KASATRALE_LEGGER",
  kadastraleLeggerAlgemeneDelen = "KADASTRALE_LEGGER_ALGEMENE_DELEN",
  rvv = "RVV",
  klim = "KLIM",
  aankoopbelofte = "AANKOOPBELOFTE",
  watertoets = "WATERTOETS",
  actaMaps = "ACTAMAPS",
  vgiUittreksel = "VGI_UITTREKSEL",
  basisakte = "BASISAKTE",
  jaarverslag1 = "JAARVERSLAG_1",
  jaarverslag2 = "JAARVERSLAG_2",
  jaarverslag3 = "JAARVERSLAG_3",
  verslagAv1 = "VERSLAG_AV_1",
  verslagAv2 = "VERSLAG_AV_2",
  verslagAv3 = "VERSLAG_AV_3",
  artikel57711 = "ARTIKEL_57711",
  balans1 = "BALANS_1",
  balans2 = "BALANS_2",
  balans3 = "BALANS_3",
  epcAlgDelen = "EPC_ALG_DELEN",
  blokpolis = "BLOKPOLIS",
  escape = "ESCAPE",
  elektrischeKeuring = "ELEKTRISCHE_KEURING",
  offerteKeuringConform = "OFFERTE_KEURING_CONFORM",
  stookolietankKeuring = "STOOKOLIETANK_KEURING",
  zonnepanelenKeuring = "ZONNENPANELEN_KEURING",
  asbestInventaris = "ASBEST_INVENTARIS",

  vgeEpcDocumentationIsolation = "VGE_EPC_DOCUMENTATION_ISOLATION",
  vgeEpcDocumentationTechnics = "VGE_EPC_DOCUMENTATION_TECHNICS",
  vgeEpcDocumentationConstruction_plans = "VGE_EPC_DOCUMENTATION_CONSTRUCTION_PLANS",
  vgeEpcDocumentationOther = "VGE_EPC_DOCUMENTATION_OTHER",
  vgeEpcDocumentationPhotos = "VGE_EPC_DOCUMENTATION_PHOTOS",

  vgeEkDocumentationSchemes = "VGE_EK_DOCUMENTATION_SCHEMES",
  vgeEkDocumentationOther = "VGE_EK_DOCUMENTATION_OTHER",
  vgeEkInvoiceRegularisation = "VGE_EK_INVOICE_REGULARISATION",

  vgeAsbestosAssignmentAgreement = "VGE_ASBESTOS_ASSIGNMENT_AGREEMENT",
  vgeAsbestosDocumentationConstructionPlans = "VGE_ASBESTOS_DOCUMENTATION_CONSTRUCTION_PLANS",
  vgeAsbestosDocumentationOther = "VGE_ASBESTOS_DOCUMENTATION_OTHER",
  vgeAsbestosDocumentationPostIntervention = "VGE_ASBESTOS_DOCUMENTATION_POST_INTERVENTION",

  vgeImmoSignPhotos = "VGE_IMMO_SIGN_PHOTOS",
  vgeImmoSignDocumentationExtra = "VGE_IMMO_SIGN_DOCUMENTATION_EXTRA",
  vgeImmoSignDesignFiles = "VGE_IMMO_SIGN_DESIGN_FILES",

  risicokaartWaterbeheerder = "RISICOKAART_WATERBEHEERDER",

  asbestosProjectAssignmentAgreement = "ASBESTOS_PROJECT_ASSIGNMENT_AGREEMENT",
  asbestosProjectDocumentationConstructionPlans = "ASBESTOS_PROJECT_DOCUMENTATION_CONSTRUCTION_PLANS",
  asbestosProjectDocumentationOther = "ASBESTOS_PROJECT_DOCUMENTATION_OTHER",
  asbestosProjectDocumentationPostIntervention = "ASBESTOS_PROJECT_DOCUMENTATION_POST_INTERVENTION",
}

export enum DocumentAccess {
  internal = "INTERNAL",
  surveyor = "SURVEYOR",
  seller = "SELLER",
  buyer = "BUYER",
}

const initialState: DocumentState = {
  all: [],
  total: undefined,
  viewing: undefined,
};

const getters: GetterTree<DocumentState, RootState> = {
  all(state: DocumentState): Document[] {
    return state.all;
  },
  total(state: DocumentState): number | undefined {
    return state.total;
  },
  viewing(state: DocumentState): Document | undefined {
    return state.viewing;
  },
};

const mutations: MutationTree<DocumentState> = {
  RESET(state: DocumentState) {
    state.all = [];
    state.viewing = undefined;
  },
  UPDATE_MODEL(state: DocumentState, payload: Document) {
    const index = state.all.findIndex((model: Document) => model.id === payload.id);

    if (index === -1) {
      state.all.push(payload);
    } else {
      Vue.set(state.all, index, payload);
    }

    if (state.viewing && state.viewing.id === payload.id) {
      state.viewing = payload;
    }
  },
  DELETE_MODEL(state: DocumentState, id: number) {
    // @ts-ignore
    const index = state.all.findIndex((model: Document) => model.id == id);

    if (index >= 0) {
      state.all.splice(index, 1);
    }

    // @ts-ignore
    if (state.viewing && state.viewing.id === id) {
      state.viewing = undefined;
    }
  },
  ADD_MODEL(state: DocumentState, payload: Document) {
    state.all.push(payload);
  },
  SET_MODELS(state: DocumentState, payload: Document[]) {
    state.all = payload;
  },
  SET_TOTAL(state: DocumentState, payload: number) {
    state.total = payload;
  },
  ADD_MODELS(state: DocumentState, payload: Document[]) {
    state.all = [...state.all, ...payload];
  },
  SET_VIEWING(state: DocumentState, payload: Document) {
    state.viewing = payload;
  },
};

const actions: ActionTree<DocumentState, RootState> = {
  create({ commit, rootState }, payload: DocumentCreatePayload) {
    return rootState.api
      .post(`documents`, payload, {
        withCredentials: true,
      })
      .then((response: { data: Document }) => {
        commit("ADD_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  patch({ commit, rootState }, payload: PatchDocumentPayload) {
    return rootState.api
      .patch(`documents/${payload.id}`, payload, { withCredentials: true })
      .then((response: { data: Activity }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  update({ commit, rootState }, { payload, id }: { payload: DocumentPayload; id: number }) {
    return rootState.api
      .put(`documents/${id}`, payload, { withCredentials: true })
      .then((response: { data: Document }) => {
        commit("UPDATE_MODEL", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  delete({ commit, rootState }, id: number) {
    return rootState.api
      .delete(`documents/${id}`, { withCredentials: true })
      .then(() => {
        commit("DELETE_MODEL", id);

        return Promise.resolve();
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },
  indexV1({ commit, rootState }, payload: { query?: string; activity_id: number; after?: Array<string> }) {
    const params = new URLSearchParams();

    if (payload) {
      if (payload.after && payload.after !== null) {
        payload.after.forEach((a: string) => {
          params.append("after[]", a);
        });
      }
      if (payload.hasOwnProperty("activity_id")) {
        params.append("q[and][or][activity_id]", payload.activity_id.toString());
      }
      if (payload.query) {
        params.append("q[and][or][name][]", `like:*${encodeURIComponent(payload.query)}*`);
        params.append("q[and][or][name][]", `match:${encodeURIComponent(payload.query)}`);
      }
    }

    params.append("s[created_at]", "desc");
    params.append("per_page", "30");

    const paramsString = decodeURIComponent(params.toString());
    return rootState.api
      .get(`documents?${paramsString}`, {
        withCredentials: true,
      })
      .then((response: { data: Document[]; total: number }) => {
        if (payload && payload.after && payload.after !== null) {
          commit("ADD_MODELS", response.data);
        } else {
          commit("SET_MODELS", response.data);
        }
        commit("SET_TOTAL", response.total);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  read({ commit, rootState }, payload: number) {
    return rootState.api
      .get(`documents/${payload}`, { withCredentials: true })
      .then((response: { data: Document }) => {
        commit("UPDATE_MODEL", response.data);

        commit("SET_VIEWING", response.data);

        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  index({ commit, rootState }, payload: IndexDocumentPayload) {
    return rootState.api
      .get(`documents?${payload ? queryFromObject(payload) : ""}`, { withCredentials: true })
      .then((response: { data: Document[] }) => {
        commit("SET_MODELS", response.data);
        return Promise.resolve(response.data);
      })
      .catch((e: ErrorResponse) => {
        return Promise.reject(e);
      });
  },

  bulkDownload({ commit, rootState }, payload: { query: IndexDocumentPayload; name: string }) {
    //Create XMLHTTP Request.
    var req = new XMLHttpRequest();
    req.withCredentials = true;
    req.open("POST", `${process.env.VUE_APP_API_URL}/documents/zip?${payload?.query ? queryFromObject(payload?.query) : ""}`, true);
    const preferredOfficeId = localStorage.getItem("hubr-preferred-office-id");
    if (preferredOfficeId && preferredOfficeId !== null) {
      req.setRequestHeader("x-office-id", preferredOfficeId);
    }
    req.responseType = "blob";
    req.onload = function (e) {
      var blob = req.response;

      var url = window.URL || window.webkitURL;
      var link = url.createObjectURL(blob);
      var a = document.createElement("a");
      a.setAttribute("download", payload.name);
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    req.send();
  },
  setViewing({ commit }, payload: Document) {
    commit("UPDATE_MODEL", payload);
    commit("SET_VIEWING", payload);
  },
  clear({ commit }) {
    commit("SET_MODELS", []);
    commit("SET_VIEWING", null);
  },
  clearViewing({ commit }) {
    commit("SET_VIEWING", null);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
