export class Auth implements CurrentAccount {
    id: number;
    email: string;
    first_name: string;
    last_name: string | null;
    media: Media | null;
    locale: string;
    permissions: string[];
    created_at: string;
    updated_at: string;
    sort: string;

    phone: string | null;
    seller_id?: number;

    biv_number?: string | null;
    sign_function?: string | null;
    signature?: Media | null;

    whise_id?: number | null;
    function_title?: string | null;
    offices?: number[];
    activities?: Array<{ id: number; name: string | null }>;

    constructor(data: CurrentAccount) {
        this.id = data.id;
        this.email = data.email;
        this.phone = data.phone;
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.whise_id = data.whise_id;
        this.function_title = data.function_title;
        this.offices = data.offices;
        this.seller_id = data.seller_id;
        this.activities = data.activities;
        this.media = data.media;
        this.locale = data.locale;
        this.biv_number = data.biv_number;
        this.sign_function = data.sign_function;
        this.signature = data.signature;
        this.permissions = data.permissions;
        this.created_at = data.created_at;
        this.updated_at = data.updated_at;
        this.sort = data.sort;
    }

    public hasPermission(permission: string) {
        return this.permissions.includes(permission);
    }

    public getRole(): "employee" | "partner" | "seller" {
        if (this.permissions.includes("account.index") || this.permissions.includes("activity.index.owned")) {
            return "employee";
        }

        if (this.permissions.includes("partner.request.read.owned")) {
            return "partner";
        }

        return "seller";
    }

    public hasRole(role: "employee" | "partner" | "seller") {
        return this.getRole() === role;
    }

    public can(ability: string) {
        return true;
    }
}
