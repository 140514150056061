var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ValidationProvider',{ref:"provider",class:{ 'disabled cursor-not-allowed': _vm.disabled, 'is-focussed': _vm.isFocussed },attrs:{"mode":_vm.eagerIfDirty,"vid":_vm.name,"name":_vm.validateAs,"rules":_vm.rules,"bails":false,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({ classes, errors }){return [(_vm.label)?_c('label',{staticClass:"block mb-[4px]",class:[classes, _vm.labelClass],attrs:{"for":`id_${_vm.name}`}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.label)}}),((_vm.rules && typeof _vm.rules === 'string' && _vm.rules.includes('required') && !_vm.rules.includes('required_if')) || (typeof _vm.rules === 'object' && _vm.rules.required) || _vm.fakeRequired)?_c('span',{staticClass:"text-red"},[_vm._v(" * ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"group relative z-0 flex items-center",class:{ '': _vm.disabled, hovering: _vm.hovering },on:{"mouseover":function($event){_vm.hovering = true},"mouseleave":function($event){_vm.hovering = false}}},[(_vm.$slots.prefix)?_c('span',{ref:"prefix",staticClass:"prefix z-10 absolute border-t border-b border-l left-0 rounded-l-[8px] px-3 h-full flex items-center bg-gray-300",class:{ '!bg-red !border-red !text-white': errors[0] && ((classes.dirty && _vm.validAtleastOnce) || _vm.formValdiated) }},[_vm._t("prefix",null,{"disabled":classes.disabled})],2):_vm._e(),((_vm.type)==='checkbox')?_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],ref:"input",class:[
        classes,
        _vm.inputClass,
        'w-full appearance-none focus:outline-none flex items-center ease-in-out',
        { '!border-red': errors[0] && ((classes.dirty && _vm.validAtleastOnce) || _vm.formValdiated) },
      ],style:({ 'padding-right': _vm.suffixWidth, 'padding-left': _vm.prefixWidth }),attrs:{"autocomplete":"false","id":`id_${_vm.name}`,"placeholder":_vm.placeholder,"step":_vm.step,"min":_vm.min,"max":_vm.max,"maxlength":_vm.maxlength,"disabled":_vm.disabled,"aria-describedby":`description_${_vm.name}`,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.localValue)?_vm._i(_vm.localValue,null)>-1:(_vm.localValue)},on:{"change":function($event){var $$a=_vm.localValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localValue=$$c}}}},{ ..._vm.$listeners, input: _vm.handleInput, focus: _vm.handleFocus, blur: _vm.handleBlur })):((_vm.type)==='radio')?_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],ref:"input",class:[
        classes,
        _vm.inputClass,
        'w-full appearance-none focus:outline-none flex items-center ease-in-out',
        { '!border-red': errors[0] && ((classes.dirty && _vm.validAtleastOnce) || _vm.formValdiated) },
      ],style:({ 'padding-right': _vm.suffixWidth, 'padding-left': _vm.prefixWidth }),attrs:{"autocomplete":"false","id":`id_${_vm.name}`,"placeholder":_vm.placeholder,"step":_vm.step,"min":_vm.min,"max":_vm.max,"maxlength":_vm.maxlength,"disabled":_vm.disabled,"aria-describedby":`description_${_vm.name}`,"type":"radio"},domProps:{"checked":_vm._q(_vm.localValue,null)},on:{"change":function($event){_vm.localValue=null}}},{ ..._vm.$listeners, input: _vm.handleInput, focus: _vm.handleFocus, blur: _vm.handleBlur })):_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],ref:"input",class:[
        classes,
        _vm.inputClass,
        'w-full appearance-none focus:outline-none flex items-center ease-in-out',
        { '!border-red': errors[0] && ((classes.dirty && _vm.validAtleastOnce) || _vm.formValdiated) },
      ],style:({ 'padding-right': _vm.suffixWidth, 'padding-left': _vm.prefixWidth }),attrs:{"autocomplete":"false","id":`id_${_vm.name}`,"placeholder":_vm.placeholder,"step":_vm.step,"min":_vm.min,"max":_vm.max,"maxlength":_vm.maxlength,"disabled":_vm.disabled,"aria-describedby":`description_${_vm.name}`,"type":_vm.type},domProps:{"value":(_vm.localValue)},on:{"input":function($event){if($event.target.composing)return;_vm.localValue=$event.target.value}}},{ ..._vm.$listeners, input: _vm.handleInput, focus: _vm.handleFocus, blur: _vm.handleBlur })),(_vm.$slots.suffix)?_c('span',{ref:"suffix",staticClass:"suffix z-10 absolute border-t border-b border-r right-0 rounded-r-[8px] px-3 h-full flex items-center bg-gray-300",class:{ '!bg-red !border-red !text-white': errors[0] && ((classes.dirty && _vm.validAtleastOnce) || _vm.formValdiated) }},[_vm._t("suffix",null,{"disabled":classes.disabled})],2):_vm._e()]),(_vm.showError && errors[0] && ((classes.dirty && _vm.validAtleastOnce) || _vm.formValdiated))?_c('span',{staticClass:"text-red text-12 block mt-2"},[_vm._v(_vm._s(errors[0]))]):_c('span',{staticClass:"text-red text-12 block mt-2"},[_vm._t("custom-error")],2)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }