import Vue from "vue";
import { ActionTree, MutationTree, GetterTree } from "vuex";
import { queryFromObject } from "@/utils/api-functions";

export interface IndexSellerTaskPayload {
    q?: {
        and: {};
    };
    per_page?: number;
}

export interface CreateSellerTaskPayload {
    task_id: number;
    seller_id: number;
}

export interface PatchSellerTaskPayload {
    id: number;
    task_id?: number;
    seller_id?: number;
}

export interface SellerTask {
    id: number;
    seller_id: number;
    task_id: number;
    sort?: Array<string | number>;
}

interface SellerTaskState {
    all: SellerTask[];
}

const initialState: SellerTaskState = {
    all: [],
};

const getters: GetterTree<SellerTaskState, RootState> = {
    all(state: SellerTaskState): SellerTask[] {
        return state.all;
    },
};

const mutations: MutationTree<SellerTaskState> = {
    RESET(state: SellerTaskState) {
        state.all = [];
    },
    ADD_MODEL(state: SellerTaskState, payload: SellerTask) {
        state.all.push(payload);
    },
    SET_MODELS(state: SellerTaskState, payload: SellerTask[]) {
        state.all = payload;
    },
    ADD_MODELS(state: SellerTaskState, payload: SellerTask[]) {
        state.all = [...state.all, ...payload];
    },
    UPDATE_MODEL(state: SellerTaskState, payload: SellerTask) {
        const index = state.all.findIndex((model: SellerTask) => model.id === payload.id);

        if (index === -1) {
            state.all.push(payload);
        } else {
            Vue.set(state.all, index, payload);
        }
    },
    DELETE_MODEL(state: SellerTaskState, id: number) {
        const index = state.all.findIndex((model: SellerTask) => model.id == id);

        if (index >= 0) {
            state.all.splice(index, 1);
        }
    },
};

const actions: ActionTree<SellerTaskState, RootState> = {
    create({ commit, rootState }, payload: CreateSellerTaskPayload) {
        return rootState.api
            .post(`seller-tasks`, payload, { withCredentials: true })
            .then((response: { data: SellerTask }) => {
                commit("UPDATE_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    patch({ commit, rootState }, payload: PatchSellerTaskPayload) {
        return rootState.api
            .patch(`seller-tasks/${payload.id}`, payload, { withCredentials: true })
            .then((response: { data: SellerTask }) => {
                commit("UPDATE_MODEL", response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    delete({ commit, rootState }, payload: number) {
        return rootState.api
            .delete(`seller-tasks/${payload}`, { withCredentials: true })
            .then((response: { data: void }) => {
                commit("DELETE_MODEL", payload);
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
