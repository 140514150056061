
import { ValidationProvider } from "vee-validate";
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import { FormInput } from "@/components/forms";
import { mask } from "vue-the-mask";

@Component({
    components: {
        ValidationProvider,
        FormInput,
    },
    directives: { mask },
})
export default class NationalInsuranceNumber extends Vue {
    @Prop() value?: string;

    @Prop() required?: boolean;

    @Prop() disabled?: boolean;

    localValue = "";

    handleRegisterNumberChange() {
        let NrNValidation = this.validateNRN(this.localValue.replaceAll(".", "").replaceAll("-", "").replaceAll(" ", ""));

        if (NrNValidation.valid) {
            let decade = NrNValidation.decade;
            let [year, month, day] = this.localValue.slice(0, 8).split(".");
            this.suggestDate(`${decade}${year}-${month}-${day}`);
        }
    }

    validateNRN(n: string) {
        if (n.length != 11) return { valid: false };

        var checkDigit = n.substr(n.length - 2, 2);
        var nrToCheck = parseInt(n.substr(0, 9));

        //@ts-ignore
        if (this.modFunction(nrToCheck) == checkDigit) return { valid: true, decade: 19 };

        nrToCheck = parseInt("2" + n.substr(0, 9));

        //@ts-ignore
        if (this.modFunction(nrToCheck) == checkDigit) {
            return { valid: true, decade: 20 };
        }

        return { valid: false };
    }

    modFunction(nr: any) {
        return 97 - (nr % 97);
    }

    @Emit("suggest-date")
    suggestDate(date: string) {

        return date;
    }

    @Emit("input")
    handleInput() {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: string) {
        this.localValue = newValue;
    }
}
