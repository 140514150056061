
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import { FormSelect } from "@/components/forms";
const cloneDeep = require("lodash/cloneDeep");

@Component({
    components: {
        FormSelect,
    },
})
export default class FolderSelect extends Vue {
    @Prop({ required: true }) value!: any;
    @Prop({ required: true }) options!: any;
    @Prop({ default: true }) single!: any;
    @Prop({ required: true }) activityId!: number;

    localValue: any = null;

    selectedOption: any = null;

    get wrappedName() {
        let name: string = this.localValue.media[0].name;

        if (name.length < 32) {
            return name;
        }

        return name.substring(0, 15) + " ... " + name.substring(name.length - 15, name.length);
    }

    setSelectedOption() {
        if (!this.localValue || !this.localValue.media || !this.localValue.media[0].folder) {
            return;
        }

        this.localValue.meta.folder = this.localValue.media[0].folder;
        this.localValue.type = this.localValue.media[0].type;

        // If the file has not a suggested type,
        // select the folder as the type
        if (!this.localValue.type) {
            this.selectedOption = this.options.find((f: any) => f.value === this.localValue.meta.folder);
            return;
        }

        this.selectedOption = this.options.find((f: any) => f.value === this.localValue.type && f.type === "placeholder");
    }

    handleValueChange() {
        this.localValue.activity_id = this.activityId;

        if (this.selectedOption.type === "placeholder") {
            this.localValue.type = this.selectedOption!.value;
            this.localValue.name = this.selectedOption.label;
            this.localValue.meta.folder = this.selectedOption!.folder;
            this.localValue.media[0].folder = this.selectedOption.value;
        } else if (this.selectedOption.type === "folder") {
            this.localValue.type = null;
            this.localValue.name = this.selectedOption.label;
            this.localValue.meta.folder = this.selectedOption!.value;
            this.localValue.media[0].folder = this.selectedOption!.value;
        }

        this.emitValue();
    }

    @Emit("input")
    emitValue() {
        console.log("emitValue");
        return this.localValue;
    }

    @Watch("value", { deep: true, immediate: true })
    handleValueChanged(newValue: any) {
        this.localValue = cloneDeep(newValue);

        this.setSelectedOption();

        if (this.localValue.type) {
            this.localValue.name = this.$t(`views.documents.placeholders.${this.localValue.type}`) as string;
        }

        if (!this.localValue.name) {
            this.localValue.name = this.localValue.media[0].name;
        }
    }
}
