
  import { Action, Getter } from "vuex-class";
  import { Component, Vue, Emit, Watch, Prop } from "vue-property-decorator";
  import { FormTextarea } from "@/components/forms";
  import FormSelect from "@/components/forms/select.vue";
  import { ActivityOrigin } from "@/store/modules/activity.store";
  import type { IndexSellerPayload, Seller } from "@/store/modules/seller.store";
  import { IndexAccountPayload } from "@/store/modules/account.store";
  import { Debounce } from "@/utils/vue-helpers";

  @Component({
    components: {
      FormTextarea,
      FormSelect,
    },
  })
  export default class OriginInput extends Vue {
    @Getter("entityOfficeIds") entityOfficeIds!: number[];

    @Action("seller/index") indexSellers!: (payload: IndexSellerPayload) => Promise<Seller[]>;
    @Action("account/index") indexAccounts!: (payload: IndexAccountPayload) => Promise<Account[]>;

    @Prop() value?: string;

    activityOrigin = ActivityOrigin;

    payload: { origin: ActivityOrigin | null; other_info?: string; employer_id?: number; surveyor_id?: number; client_id?: number } = {
      origin: null,
      other_info: "",
      employer_id: undefined,
      surveyor_id: undefined,
      client_id: undefined,
    };

    localSellers: Seller[] = [];

    localEmployers: Account[] = [];

    get originOptions() {
      return Object.values(ActivityOrigin).map((a) => {
        return {
          value: a,
          label: this.$t(`views.dossier.origin.${a}`),
        };
      });
    }

    get employerOptions() {
      return this.localEmployers.map((account) => {
        return {
          value: account.id,
          label: `${account.first_name} ${account.last_name}`,
        };
      });
    }

    get surveyorOptions() {
      return this.localEmployers.map((account) => {
        return {
          value: account.id,
          label: `${account.first_name} ${account.last_name}`,
        };
      });
    }

    get clientOptions() {
      return this.localSellers.map((seller) => {
        return {
          value: seller.id,
          label: `${seller.first_name} ${seller.last_name}`,
        };
      });
    }

    async handleFetchEmployers() {
      if (this.localEmployers.length > 0) {
        return;
      }

      this.localEmployers = await this.indexAccounts({ q: { or: { roles: ["administrator", "user"] } }, per_page: 99 });
    }

    @Debounce(500)
    async handleFetchSellers(query: string = "", perPage: number = 999, loading: any = () => {}) {
      if (this.localSellers.length > 0 && !query) {
        return;
      }

      let q = {};
      if (query) {
        q = {
          and: {
            or: {
              first_name: [`like^1:${query}*`, `match^1:${query}`],
              last_name: [`like^1:${query}*`, `match^1:${query}`],
              full_name: [`like^1:${query}*`, `match^1:${query}`],
            },
          },
        };
      }

      // @ts-ignore
      this.localSellers = await this.indexSellers({ q: { ...q, or: { office_ids: this.entityOfficeIds } }, per_page: perPage });

      loading(false);
    }

    handleClientOptionsSearch(query: string, loading: any) {
      if (query.length > 0) {
        loading(true);
      }

      this.handleFetchSellers(query, 30, loading);
    }

    @Emit("input")
    handleInput() {
      if (this.payload.origin === ActivityOrigin.Employer) {
        return {
          origin: this.payload.origin,
          employer_id: this.payload.employer_id,
        };
      }

      if (this.payload.origin === ActivityOrigin.Surveyor) {
        return {
          origin: this.payload.origin,
          surveyor_id: this.payload.surveyor_id,
        };
      }

      if (this.payload.origin === ActivityOrigin.Client) {
        return {
          origin: this.payload.origin,
          client_id: this.payload.client_id,
        };
      }

      if (this.payload.origin === ActivityOrigin.Other) {
        return {
          origin: this.payload.origin,
          other_info: this.payload.other_info,
        };
      }

      return {
        origin: this.payload.origin,
      };
    }
    @Watch("value", { deep: true, immediate: true })
    handleValueChange(newValue: { origin: ActivityOrigin | null; other_info?: string; employer_id?: number; surveyor_id?: number; client_id?: number }) {
      this.payload = newValue;
    }

    @Watch("payload.origin")
    handleOriginChanged(newValue: ActivityOrigin | null) {
      if (!newValue) {
        return;
      }
      if (newValue === ActivityOrigin.Employer) {
        this.handleFetchEmployers();
      }
      if (newValue === ActivityOrigin.Surveyor) {
        this.handleFetchEmployers();
      }
      if (newValue === ActivityOrigin.Client) {
        this.handleFetchSellers();
      }
      if (newValue === ActivityOrigin.Purchase) {
        this.handleFetchSellers();
      }
    }
  }
